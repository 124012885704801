import {AbstractFormService} from "@ypa/data-access/abstract-form";
import {BaseEntityInterface} from "@ypa/types/base-entity";
import {ControlsOf, FormArray, FormGroup} from "@ngneat/reactive-forms";
import {DateRangeAndPersonsType,} from "@ypa/types/report-tab";
import {Validators} from "@angular/forms";
import {BaseRelatedClubInterface} from "@ypa/types/related-club";
import {FormArrayValidatorHelper} from "@ypa/helpers/custom-validator";
import {BaseRelatedUserInterface} from "@ypa/types/related-user";


export abstract class AbstractReportTabWithRangeFormService<T extends BaseEntityInterface> extends AbstractFormService<T> {
    // addDateRangeControls(form: FormGroup<ControlsOf<Partial<T>>>, params: Partial<KpiReportTabInterface | ProfileReportTabInterface>, emitEvent = false) {
    addDateRangeControls(form: FormGroup<ControlsOf<Partial<T>>>, params: Partial<DateRangeAndPersonsType>, emitEvent = false) {
        form.addControl('dateAtFrom', this.fb.control(params.dateAtFrom, params.dateAtFrom ? [Validators.required] : []), {emitEvent});
        form.addControl('dateAtTo', this.fb.control(params.dateAtTo, params.dateAtTo ? [Validators.required]: []), {emitEvent});
        form.addControl('days', this.fb.control(params.days, params.days != null ? [Validators.required] : []), {emitEvent});
        form.addControl('clubs', this.getRelatedClubsFormArray(params.clubs), {emitEvent})
        form.addControl('athletes', this.getRelatedAthletesFormArray(params.athletes), {emitEvent})
    }

    removeDateRangeControls(form: FormGroup<ControlsOf<Partial<T>>>, emitEvent = false) {
        form.removeControl('dateAtFrom', {emitEvent});
        form.removeControl('dateAtTo', {emitEvent});
        form.removeControl('days', {emitEvent});

        form.removeControl('clubs', {emitEvent});
        form.removeControl('athletes', {emitEvent});
    }

    getRelatedClubsFormArray(clubs: BaseRelatedClubInterface[] | undefined) {
        return new FormArray(!clubs ? [] : clubs.map(c => this.getRelatedClubForm(c.clubId), FormArrayValidatorHelper.minLength(1)));
    }

    getRelatedAthletesFormArray(athletes: BaseRelatedUserInterface[] | undefined) {
        return new FormArray(!athletes ? [] : athletes.map(a => this.getRelatedAthleteForm(a.userId)), FormArrayValidatorHelper.minLength(1))
    }

    getRelatedClubForm(clubId: number): FormGroup<ControlsOf<BaseRelatedClubInterface>> {
        return this.fb.group({
            clubId: this.fb.control(clubId, [Validators.required])
        });
    }

    getRelatedAthleteForm(userId: number): FormGroup<ControlsOf<BaseRelatedUserInterface>> {
        return this.fb.group({
            userId: this.fb.control(userId, [Validators.required])
        });
    }
}
