import {Injectable} from '@angular/core';
import {createStore} from '@ngneat/elf';
import {
    withEntities,
} from '@ngneat/elf-entities';
import {MetricInterface, MetricParams} from '@ypa/types/metric';
import {
    AbstractEntityWithClubRelationRepository
} from "@ypa/state-management/shared/abstract";
import {BaseMetricClubInterface, MetricClubInterface, MetricClubParams} from "@ypa/types/metric-club";
import {MetricClubService} from "@ypa/data-access/metric-club";

const name = 'METRICS';

@Injectable({providedIn: "root"})
export class MetricRepository extends AbstractEntityWithClubRelationRepository<MetricInterface, MetricClubInterface, BaseMetricClubInterface, MetricParams, MetricClubParams> {
    protected readonly store = createStore({name}, withEntities<MetricInterface>());

    constructor(
        private metricClubService: MetricClubService
    ) {
        super(metricClubService);
    }

    protected isMatchedEntityWithClubRelation(clubRelations: MetricClubInterface, obj: MetricInterface): boolean {
        return clubRelations.metricId === obj.id;
    }
}
