import {Injectable} from "@angular/core";
import {TranslateLoader} from "@ngx-translate/core";
import {Observable, of} from "rxjs";
import {LanguageCodeEnum} from "@ypa/enums/language-code";

import * as validationJsonEn from '../json/en/validation.json';
import * as localeJsonEn from '../json/en/locale.json';
import * as sharedLocaleJsonEn from '../json/en/shared.json';
import * as validationJsonDk from '../json/da/validation.json';
import * as localeJsonDk from '../json/da/locale.json';
import * as sharedLocaleJsonDk from '../json/da/shared.json';
import * as bodyJsonDk from '../json/da/body.json';
import * as bodyJsonEn from '../json/en/body.json';
import * as daysJsonDk from '../json/da/days.json';
import * as daysJsonEn from '../json/en/days.json';
import * as coachJsonDK from '../json/da/coach.json';
import * as coachJsonEn from '../json/en/coach.json';

@Injectable()
export class JsonLoaderService extends TranslateLoader {
    getTranslation(lang: string): Observable<any> {

        const translateEn = {
            ...localeJsonEn,
            ...validationJsonEn,
            ...sharedLocaleJsonEn,
            ...bodyJsonEn,
            ...daysJsonEn,
            ...coachJsonEn
        };
        const translateDk = {
            ...localeJsonDk,
            ...validationJsonDk,
            ...sharedLocaleJsonDk,
            ...bodyJsonDk,
            ...daysJsonDk,
            ...coachJsonDK
        };

        let translate = {};

        switch (lang) {
            case (LanguageCodeEnum.English):
                translate = translateEn;
                break;
            case(LanguageCodeEnum.Danish):
                translate = translateDk;
                break;
            default:
                translate = translateEn;
        }

        return of(translate);
    }
}
