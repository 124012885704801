export enum MeasureTypeEnum {
    temperatureUnits = "TEMPERATURE_UNITS",
    quantityUnits = "QUANTITY_UNITS",
    weightUnits = "WEIGHT_UNITS",
    lengthUnits = "LENGTH_UNITS",
    volumeUnits = "VOLUME_UNITS",
    timeUnits = "TIME_UNITS",
    forceUnits = "FORCE_UNITS"
}

