import {MeasureAccordance} from "@ypa/types/environments";
import {
    ForceUnitsEnum,
    LengthImperialUnitsEnum,
    LengthMetricUnitsEnum,
    MeasureTypeEnum,
    QuantityUnitsEnum,
    TemperatureUnitsEnum,
    TimeUnitsEnum,
    VolumeImperialUnitsEnum,
    VolumeMetricUnitsEnum,
    WeightImperialUnitsEnum,
    WeightMetricUnitsEnum
} from "@ypa/enums/units";

export class EnvironmentHelper {
    static getMeasureAccordance(): MeasureAccordance[] {
        return [
            new MeasureAccordance(MeasureTypeEnum.quantityUnits, {
                metric: QuantityUnitsEnum,
                imperial: QuantityUnitsEnum
            }),
            new MeasureAccordance(MeasureTypeEnum.temperatureUnits, {
                metric: TemperatureUnitsEnum,
                imperial: TemperatureUnitsEnum
            }),
            new MeasureAccordance(MeasureTypeEnum.weightUnits, {
                metric: WeightMetricUnitsEnum,
                imperial: WeightImperialUnitsEnum
            }),
            new MeasureAccordance(MeasureTypeEnum.lengthUnits, {
                metric: LengthMetricUnitsEnum,
                imperial: LengthImperialUnitsEnum
            }),
            new MeasureAccordance(MeasureTypeEnum.volumeUnits, {
                metric: VolumeMetricUnitsEnum,
                imperial: VolumeImperialUnitsEnum
            }),
            new MeasureAccordance(MeasureTypeEnum.timeUnits, {
                metric: TimeUnitsEnum,
                imperial: TimeUnitsEnum
            }),
            new MeasureAccordance(MeasureTypeEnum.forceUnits, {
                metric: ForceUnitsEnum,
                imperial: ForceUnitsEnum
            })
        ]
    }
}
