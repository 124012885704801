import {inject, Injectable} from '@angular/core';
import {AbstractFormService} from "@ypa/data-access/abstract-form";
import {RoleInterface} from "@ypa/types/role";
import {ControlsOf, FormGroup} from "@ngneat/reactive-forms";
import {Validators} from "@angular/forms";
import {RoleTypeEnum} from "@ypa/enums/role-type";
import {LanguageFormService} from "@ypa/data-access/language";
import {LanguageInterface} from "@ypa/types/language";

@Injectable()
export class RoleFormService extends AbstractFormService<RoleInterface> {

   private languageFormService = inject(LanguageFormService);

    getForm(params: Partial<RoleInterface>): FormGroup<ControlsOf<Partial<RoleInterface>>> {

        let form = {}

        if (params.parentId !== null && typeof params.parentId !== "undefined") {
            form = {
                parentId: this.fb.control(params.parentId, [Validators.required]),
            }
        }

        return this.fb.group({
            ...form,
            parentId: this.fb.control(params.parentId, [Validators.required]),
            nameTranslationField: this.fb.group(this.languageFormService.getTranslationControls(params.nameTranslationField)),
            type: this.fb.control(RoleTypeEnum.coach, Validators.required),
        });
    }
}
