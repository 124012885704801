import {Injectable} from "@angular/core";
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {BaseCommonWidgetInterface, CommonWidgetInterface, WidgetParams} from "@ypa/types/widget";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "@ypa/constants/environments";
import {ReportTabWidgetRepository} from "state-management/shared/report-tab-widget";
import {EntityListInterface} from "@ypa/types/base-entity";
import {CommonReportTabInterface} from "@ypa/types/report-tab";

@Injectable({providedIn: "root"})
export class ReportTabWidgetService extends AbstractEntityRepositoryServices<CommonWidgetInterface, BaseCommonWidgetInterface, WidgetParams> {
    constructor(
        private http: HttpClient,
        private reportTabWidgetRepository: ReportTabWidgetRepository
    ) {
        super(reportTabWidgetRepository)
    }

    protected createReq(form: Partial<CommonWidgetInterface> | BaseCommonWidgetInterface): Observable<CommonWidgetInterface> {
        return this.http.post<CommonWidgetInterface>(`${environment.apiUrl}/coach/report-tab-widget`, form);
    }

    protected getAllReq(): Observable<CommonWidgetInterface[]> {
        return this.http.get<CommonWidgetInterface[]>(`${environment.apiUrl}/coach/report-tab-widget`);
    }

    protected getByIdReq(id: number): Observable<CommonWidgetInterface> {
        return this.http.get<CommonWidgetInterface>(`${environment.apiUrl}/coach/report-tab-widget/${id}`);
    }

    protected getByReq(params: WidgetParams): Observable<CommonWidgetInterface[]> {
        return this.http.get<CommonWidgetInterface[]>(`${environment.apiUrl}/coach/report-tab-widget`, {params: params as {}});
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/coach/report-tab-widget/${id}`);
    }

    protected updateReq(id: number, form: Partial<CommonWidgetInterface>): Observable<CommonWidgetInterface> {
        return this.http.put<CommonWidgetInterface>(`${environment.apiUrl}/coach/report-tab-widget/${id}`, form);
    }

    protected updateListReq(list: EntityListInterface<CommonWidgetInterface>): Observable<EntityListInterface<CommonWidgetInterface>> {
        return this.http.post<EntityListInterface<CommonWidgetInterface>>(`${environment.apiUrl}/coach/report-tab-widget/list`, list);
    }
}
