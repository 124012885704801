import {AbstractErrorInterceptor, OvdalError} from "@ypa/ovdal/ovdal-errors";
import {Injectable} from "@angular/core";
import {ToastController} from "@ionic/angular";

@Injectable()
export class ErrorInterceptor extends AbstractErrorInterceptor {
    constructor(
        private toastController: ToastController,
    ) {
        super();
    }

    protected showError(error: OvdalError) {
        this.toastController.create({
            message: error.message,
            duration: 1500,
            position: "top",
            keyboardClose: true
        }).then(toast => toast.present());
    }
}
