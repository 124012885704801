import {Injectable} from "@angular/core";
import {createStore, withProps} from "@ngneat/elf";
import {Timezone} from "@ypa/types/shared/timezone";
import {localStorageStrategy, persistState} from "@ngneat/elf-persist-state";
import {AbstractPropertyRepository} from "../../../../shared/abstract/src/lib/abstract-property.repository";
import {TimezoneHelper} from "@ypa/helpers/timezone";

const name = 'USER_TIMEZONE';

@Injectable({providedIn: "root"})
export class UserTimezoneRepository extends AbstractPropertyRepository<Timezone> {
    protected readonly store = createStore(
        {name},
        withProps<{data: Timezone}>({data: TimezoneHelper.getByOffset(1)})
    );

    private readonly persist = persistState(
        this.store, {
            key: name,
            storage: localStorageStrategy,
        }
    );
}
