import {AbstractFormService} from "@ypa/data-access/abstract-form";
import {CalculationInterface} from "@ypa/types/calculation";
import {ControlsOf, FormGroup} from "@ngneat/reactive-forms";
import {Validators} from "@angular/forms";
import {CalculationTypeEnum} from "@ypa/enums/calculation-type";
import {Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class CalculationFormService extends AbstractFormService<CalculationInterface> {
    getForm(params: Partial<CalculationInterface>): FormGroup<ControlsOf<Partial<CalculationInterface>>> {
        let form = {};

        if (params.id !== null && typeof params.id !== "undefined") {
            form = {
                id: this.fb.control(params.id, [Validators.required]),
            }
        }

        return this.fb.group({
            ...form,
            type: this.fb.control(params.type ? params.type : CalculationTypeEnum.group, [Validators.required]),
            metricId: this.fb.control(params.metricId != null ? params.metricId : null),
            parentId: this.fb.control(params.parentId != null ? params.parentId : null),
            position: this.fb.control(params.position != null ? params.position : 0),
        }) as FormGroup<ControlsOf<Partial<CalculationInterface>>>;
    }
}
