import {Injectable, Injector} from "@angular/core";
import {AbstractFormService} from "@ypa/data-access/abstract-form";
import {EmailRegisterUserInterface, PhoneRegisterUserInterface, RegisterUserInterface} from "@ypa/types/user";
import {ControlsOf, FormGroup} from "@ngneat/reactive-forms";
import {Validators} from "@angular/forms";
import {CustomPasswordValidatorHelper, CustomPhoneValidatorHelper} from "@ypa/helpers/custom-validator";

@Injectable()
export class UserAuthFormService extends AbstractFormService<RegisterUserInterface> {
    getForm(params: Partial<RegisterUserInterface>): FormGroup<Partial<ControlsOf<RegisterUserInterface>>> {
        return this.fb.group({
            name: this.fb.control(params.name ? params.name : '', [Validators.required]),
            password: this.fb.control('', [Validators.required]),
            passwordConfirm: this.fb.control('', [Validators.required]),
            agreeTerms: this.fb.control(params.agreeTerms ? params.agreeTerms : false, [Validators.requiredTrue]),
            uiLanguageId: this.fb.control(params.uiLanguageId ? params.uiLanguageId : null, [Validators.required]),
            nativeLanguageId: this.fb.control(params.nativeLanguageId ? params.nativeLanguageId : null),
        }, {
            validators: [CustomPasswordValidatorHelper.matchValidator('password', 'passwordConfirm')]
        });
    }

    getEmailForm(params: Partial<EmailRegisterUserInterface>): FormGroup<Partial<ControlsOf<EmailRegisterUserInterface>>> {
        const form = this.getForm(params);
        form.addControl('email', this.fb.control(params.email ? params.email : '', [Validators.required, Validators.email]));
        form.addControl('invitationToken', this.fb.control(params.invitationToken ? params.invitationToken : '', [Validators.required]));
        return form;
    }

    getPhoneForm(params: Partial<PhoneRegisterUserInterface>): FormGroup<Partial<ControlsOf<PhoneRegisterUserInterface>>> {
        const form = this.getForm(params);
        form.addControl('phone', this.fb.control(params.phone ? params.phone : '', [Validators.required, CustomPhoneValidatorHelper.matchPhone()]));
        form.addControl('code', this.fb.control(params.code ? params.code : '', [Validators.required]));
        return form;
    }
}
