import {Injectable} from '@angular/core';
import {CurrentClubRepository} from "@ypa/state-management/shared/current-club";
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class CurrentClubService {
    constructor(private currentClubRepository: CurrentClubRepository) {
    }

    set(clubId: number) {
        this.currentClubRepository.update(clubId)
    }

    get(): Observable<number> {
        return this.currentClubRepository.data$;
    }

    getSnapShot(): number {
        return this.currentClubRepository.getSnapshot()
    }

    isEmpty(): boolean {
        return this.currentClubRepository.isEmpty;
    }

    clear() {
        this.currentClubRepository.clear()
    }
}
