import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, tap} from 'rxjs';
import {environment} from '@ypa/constants/environments';
import {BaseRoleInterface, RoleInterface, RoleParams} from '@ypa/types/role';
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {RoleRepository} from "@ypa/state-management/shared/role";
import {BaseEntityParams, EntityListInterface} from "@ypa/types/base-entity";

@Injectable({providedIn: "root"})
export class RoleService extends AbstractEntityRepositoryServices<RoleInterface, BaseRoleInterface, RoleParams> {
    constructor(
        private http: HttpClient,
        private roleRepository: RoleRepository
    ) {
        super(roleRepository)
    }

    protected getAllReq(): Observable<RoleInterface[]> {
        return this.http.get<RoleInterface[]>(`${environment.apiUrl}/role`);
    }

    protected createReq(form: BaseRoleInterface | Partial<RoleInterface>): Observable<RoleInterface> {
        return this.http.post<RoleInterface>(`${environment.apiUrl}/role`, form);
    }

    protected updateReq(id: number, form: Partial<RoleInterface>): Observable<RoleInterface> {
        return this.http.patch<RoleInterface>(`${environment.apiUrl}/role/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<null>(`${environment.apiUrl}/role/${id}`);
    }

    protected getByIdReq(id: number): Observable<RoleInterface> {
        return this.http.get<RoleInterface>(`${environment.apiUrl}/role/${id}`);
    }

    protected getByReq(params: BaseEntityParams): Observable<RoleInterface[]> {
        return this.http.get<RoleInterface[]>(`${environment.apiUrl}/role`, {params: params as {}});
    }

    protected updateListReq(list: EntityListInterface<RoleInterface>): Observable<EntityListInterface<RoleInterface>> {
        throw new Error('Method not implemented.');
    }
}
