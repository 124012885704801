import {Observable, switchMap, tap} from "rxjs";
import {AbstractPropertyRepository} from "@ypa/state-management/shared/abstract";
import {BaseEntityInterface} from "@ypa/types/base-entity";

export abstract class AbstractPropertyRepositoryService<T extends BaseEntityInterface, TBase> {

    data$: Observable<T>;

    protected constructor(protected repository: AbstractPropertyRepository<T>) {
        this.initRepositoryObservers(repository);
    }

    get(hard = false): Observable<T> {
        if (!hard && !this.repository.isEmpty) {
            return this.data$;
        }

        return this.getReq().pipe(
            tap(data => this.repository.update(data)),
            switchMap(() => this.data$)
        );
    }

    create(form: TBase | Partial<T>): Observable<T> {
        return this.createReq(form).pipe(
            tap(data => this.repository.update(data))
        );
    }

    update(id: number, form: Partial<T>): Observable<T> {
        return this.updateReq(id, form).pipe(
            tap(data => this.repository.update(data))
        );
    }

    remove(id: number): Observable<any> {
        return this.removeReq(id).pipe(
            tap(() => this.repository.clear())
        )
    }

    clear() {
        this.repository.clear()
    }

    getSnapshot(): T {
        return this.repository.getSnapshot();
    }

    protected initRepositoryObservers(repository: AbstractPropertyRepository<T>) {
        this.data$ = repository.data$;
    }

    protected abstract getReq(): Observable<T>;

    protected abstract createReq(form: TBase | Partial<T>): Observable<T>;

    protected abstract updateReq(id: number, form: Partial<T>): Observable<T>;

    protected abstract removeReq(id: number): Observable<any>;
}
