import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription, filter, switchMap} from "rxjs";
import {SystemEventsService} from "@ypa/data-access/system-events";
import {UserAuthService, UserService} from "@ypa/data-access/user";
import {AuthTokenRepository} from "@ypa/state-management/shared/auth-token";
import {ReportTabsPageService} from "./pages/dashboard/pages/reports/services/report-tabs-page.service";
import {CurrentClubService} from "@ypa/data-access/current-club";
import {CalculationService} from "@ypa/data-access/calculation";
import {ClubService} from "@ypa/data-access/club";
import {CollectorService} from "@ypa/data-access/collector";
import {FormService} from "@ypa/data-access/form";
import {FormClubService} from "@ypa/data-access/form-club";
import {MetricHintService} from "@ypa/data-access/metric-hint";
import {MetricHintTextService} from "@ypa/data-access/metric-hint-text";
import {MetricService} from "@ypa/data-access/metric";
import {MetricReportService} from "@ypa/data-access/metric-report";
import {MetricClubService} from "@ypa/data-access/metric-club";
import {TasksService} from "@ypa/data-access/tasks";
import {RoleService} from "@ypa/data-access/role";
import {SportService} from "@ypa/data-access/sport";
import {TagService} from "@ypa/data-access/tag";
import {UserDashboardMetricService} from "@ypa/data-access/user-dashboard-metric";
import {UserDashboardMetricClubService} from "@ypa/data-access/user-dashboard-metric-club";
import {CoachDashboardService} from "@ypa/data-access/coach-dashboard";
import {CoachDashboardMetricService} from "@ypa/data-access/coach-dashboard-metric";
import {UserRoleService} from "@ypa/data-access/user-role";
import {LanguageService} from "@ypa/data-access/language";
import {CurrentRoleService} from "@ypa/data-access/current-role";
import {ReportTabService} from "data-access/report-tab";
import {ReportTabWidgetService} from "data-access/report-tab-widget";
import {UserTimezoneRepository} from "@ypa/state-management/pwa/timezone";
import {TimetableService} from "data-access/timetable";
import { MixpanelAnalyticsService } from '@ypa/libs/analytics';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'ypa-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();

    constructor(
        private systemEventsService: SystemEventsService,
        private currentClubService: CurrentClubService,
        private authTokenRepository: AuthTokenRepository,
        private userAuthService: UserAuthService,
        private calculationService: CalculationService,
        private clubService: ClubService,
        private collectorService: CollectorService,
        private formService: FormService,
        private formClubService: FormClubService,
        private metricHintService: MetricHintService,
        private metricHintTextService: MetricHintTextService,
        private metricService: MetricService,
        private metricReportService: MetricReportService,
        private metricClubService: MetricClubService,
        private tasksService: TasksService,
        private roleService: RoleService,
        private sportService: SportService,
        private tagService: TagService,
        private userDashboardMetricService: UserDashboardMetricService,
        private userDashboardMetricClubService: UserDashboardMetricClubService,
        private coachDashboardService: CoachDashboardService,
        private coachDashboardMetricService: CoachDashboardMetricService,
        private router: Router,
        private userRoleService: UserRoleService,
        private userTimezoneRepository: UserTimezoneRepository,
        private userService: UserService,
        private languageService: LanguageService,
        private currentRoleService: CurrentRoleService,
        private reportTabService: ReportTabService,
        private reportTabWidgetService: ReportTabWidgetService,
        private reportTabsPageService: ReportTabsPageService,
        private timetableService: TimetableService,
        private mixpanelAnalyticsService: MixpanelAnalyticsService
    ) {
    }

    ngOnInit() {
        this.handleLogoutEvent();
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
          ).subscribe((event: NavigationEnd) => {
            // Call trackPageView with each new route
            this.mixpanelAnalyticsService.trackPageView('coach', event.urlAfterRedirects);
          });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private handleLogoutEvent() {
        this.subscription.add(
            this.systemEventsService.logout$.pipe(
                switchMap(() => this.userAuthService.logout())
            ).subscribe(() => {
                this.currentClubService.clear();
                this.authTokenRepository.clear();
                this.calculationService.clear();
                this.clubService.clear();
                this.collectorService.clear();
                this.formService.clear();
                this.formClubService.clear();
                this.metricHintService.clear();
                this.metricHintTextService.clear();
                this.metricService.clear();
                this.metricReportService.clear();
                this.metricClubService.clear();
                this.tasksService.clear();
                this.roleService.clear();
                this.userAuthService.clear();
                this.userRoleService.clear();
                this.userDashboardMetricService.clear()
                this.userDashboardMetricClubService.clear()
                this.userTimezoneRepository.clear()
                this.userService.clear()
                this.sportService.clear();
                this.tagService.clear();
                this.coachDashboardService.clear();
                this.coachDashboardMetricService.clear();
                this.currentRoleService.clear();
                this.reportTabService.clear();
                this.reportTabWidgetService.clear();
                this.reportTabsPageService.reset();
                this.languageService.clear();
                this.timetableService.clear();
                this.mixpanelAnalyticsService.clearUserId();
            })
        );
    }
}
