import {Injectable} from "@angular/core";
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";
import {TaskInterface, TaskParams} from "@ypa/types/task";

const name = 'NOTIFICATIONS';

@Injectable({providedIn: "root"})
export class TasksRepository extends AbstractEntityRepository<TaskInterface, TaskParams> {
    protected readonly store = createStore(
        {name},
        withEntities<TaskInterface>()
    );
}
