import {inject, Injectable} from "@angular/core";
import {BehaviorSubject, map, Observable, of, switchMap} from "rxjs";
import {CommonReportTabInterface} from "@ypa/types/report-tab";
import {ReportTabTypeEnum} from "enums/report-tab-type";
import {ReportTabService} from "data-access/report-tab";
import {CurrentClubService} from "@ypa/data-access/current-club";

@Injectable({providedIn: 'root'})
export class ReportTabsPageService {
    private readonly defaultTabId = -1;

    private virtualTabsSubject = new BehaviorSubject<CommonReportTabInterface[]>([]);

    private reportTabService = inject(ReportTabService);
    private currentClubService = inject(CurrentClubService);

    addVirtualTab(tab: CommonReportTabInterface) {
        this.virtualTabsSubject.next([
            ...this.virtualTabsSubject.value,
            tab
        ])
    }

    saveTab(tab: CommonReportTabInterface): Observable<CommonReportTabInterface> {
        return this.reportTabService.create(tab)
    }

    getReports(): Observable<CommonReportTabInterface[]> {
        return this.currentClubService.get().pipe(
            switchMap((clubId)=> clubId > 0 ? this.reportTabService.getBy({clubId}) : of([])),
            // switchMap(tabs => {
            //     if (!tabs.length && !this.virtualTabsSubject.value.length) {
            //         this.addDefaultTab();
            //     }
            //     return this.virtualTabsSubject.pipe(
            //         map(virtualTabs => {
            //             return [...virtualTabs, ...tabs] as CommonReportTabInterface[];
            //         })
            //     )
            // })
        )
    }

    removeTab(tab: CommonReportTabInterface): Observable<unknown> {
        if (tab.id < 0) {
            this.virtualTabsSubject.next(this.virtualTabsSubject.value.filter(t => t.id !== tab.id))
            return of(null);
        } else {
            return this.reportTabService.remove(tab.id);
        }
    }

    reset() {
        this.virtualTabsSubject.next([]);
    }

    private addDefaultTab() {
        this.addVirtualTab({
            id: this.defaultTabId,
            name: 'Your first reports',
            type: ReportTabTypeEnum.kpi,
            clubId: this.currentClubService.getSnapShot()
        })
    }
}
