import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
    deleteEntitiesByPredicate,
    selectManyByPredicate,
    withEntities,
} from '@ngneat/elf-entities';
import {CalculationInterface, CalculationParams} from '@ypa/types/calculation';
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";

const name = 'CALCULATIONS';

@Injectable({providedIn: "root"})
export class CalculationRepository extends AbstractEntityRepository<CalculationInterface, CalculationParams>{

    protected readonly store = createStore(
        { name },
        withEntities<CalculationInterface>()
    );

    removeByMetricId(metricId: number) {
        this.store.update(
            deleteEntitiesByPredicate((calculation) => calculation.metricId === metricId)
        )
    }

    getByMetricAndParentIds(metricId: number, parentId: number) {
        return this.store.pipe(
            selectManyByPredicate(
                (calculation: CalculationInterface) =>
                    calculation.parentId === parentId &&
                    calculation.metricId === metricId
            )
        );
    }
}
