import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import {RegExpHelper} from "./reg-exp.helper";

export class CustomValidatorsHelper {
    public static hexColor(): ValidatorFn {
        return (control: AbstractControl) => {
            if (!control.value) {
                return null;
            }
            if (!control.value) {
                return null;
            }
            const isValid = RegExpHelper.hexColor().test(control.value);

            return isValid ? null : {hexColor: true};
        }
    }

    static controlAGreaterControlB(controlA: string, controlB: string, allowEqual = true): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            if (control instanceof FormGroup && control.controls) {
                const a = +control.controls[controlA]?.value;
                const b = +control.controls[controlB].value;

                if (!isNaN(a) && !isNaN(b)) {
                    if (allowEqual) {
                        if (a < b) {
                            return {controlAGreaterOrEqualControlB: true}
                        }
                    } else {
                        if (a <= b) {
                            return {controlAGreaterControlB: true}
                        }
                    }
                }
            }
            return null;
        };
    }
}
