import {delay, Observable, Subject, throwError} from 'rxjs';
import {catchError, finalize, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {UserAuthService} from '@ypa/data-access/user';
import {AuthTokenInterface} from '@ypa/types/auth-token';
import {environment} from '@ypa/constants/environments';
import {AuthTokenRepository} from '@ypa/state-management/shared/auth-token';
import {SystemEventsService} from '@ypa/data-access/system-events';
import {Router} from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private isRefreshingToken = false;

    private readonly refreshUrl = `${environment.apiUrl}/auth/refresh`;

    private static readonly urlsToExclude = [
        'articles',
        'article-categories',
        'coach-helps',
        'coach-pages',
        'uploads',
        'content-pages',
        'app-version-guides'
    ]

    private refreshCompleteSubject: Subject<string> = new Subject();


    constructor(
        private auth: AuthTokenRepository,
        private userAuthService: UserAuthService,
        private systemEventsService: SystemEventsService,
        private router: Router
    ) {
    }

    private static addToken(
        req: HttpRequest<any>,
        token: string
    ): HttpRequest<any> {
        if (this.isValidRequestForInterceptor(req.url)) {
            return req.clone({setHeaders: {Authorization: 'Bearer ' + token}});
        }
        return req;
    }

    private static isValidRequestForInterceptor(requestUrl: string): boolean {
        for (const path of this.urlsToExclude) {
            if (new RegExp(path).test(requestUrl)) {
                return false;
            }
        }
        return true;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        let token = this.auth.getAccessTokenSnapshot();
        if (req.url === this.refreshUrl) {
            token = this.auth.getRefreshTokenSnapshot();
        }
        return next.handle(AuthInterceptor.addToken(req, token)).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {

                    switch ((<HttpErrorResponse>error).status) {
                        case 400:
                            return this.handle400Error(error);
                        case 401:
                            return this.handle401Error(req, next, error);
                        default:
                            return throwError(error);

                    }
                }

                return throwError(error);
            })
        );
    }

    handle400Error(error: any) {
        if (
            error &&
            error.status === 400 &&
            error.error &&
            error.error.error === 'invalid_grant'
        ) {
            // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
            return this.logoutUser();
        }

        return throwError(error);
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
        console.log(`Processing 401 error for ${req.url}`);

        if (req.url.includes('refresh')) {
            this.isRefreshingToken = false;
            return this.logoutUser();
        }
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;


            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            const refreshToken = this.auth.getRefreshTokenSnapshot();
            if (!refreshToken) {
                this.isRefreshingToken = false;
                return throwError({text: 'You are not logged in'});  // TODO should be like server error
            }

            return this.userAuthService.refresh().pipe(
                catchError(error => {
                    console.error(error);
                    this.isRefreshingToken = false;
                    return this.logoutUser();
                }),
                switchMap((newToken: AuthTokenInterface) => {

                    if (newToken) {
                        this.refreshCompleteSubject.next(newToken.accessToken);

                        this.auth.setAccessToken(newToken.accessToken);
                        if (newToken.refreshToken) {
                            this.auth.setRefreshToken(newToken.refreshToken)
                        }

                        return next.handle(
                            AuthInterceptor.addToken(req, newToken.accessToken)
                        );
                    }
                    // If we don't get a new token, we are in trouble so logout.
                    return this.logoutUser();
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                })
            );

        }
        return this.refreshCompleteSubject.pipe(
            delay(50),
            switchMap((token) => {
                return next.handle(
                    AuthInterceptor.addToken(req, token)
                );
            })
        )
    }

    private logoutUser() {
        this.isRefreshingToken = false;
        this.systemEventsService.fireLogout();
        this.auth.clear();
        this.router.navigate(['/auth']).then();
        return throwError({text: 'Your login time is expired'}); // TODO should be like server error
    }
}
