import {Injectable} from "@angular/core";
import {AbstractFormService} from "@ypa/data-access/abstract-form";
import {TimetableInterface} from "@ypa/types/timetable";
import {CommonReportTabInterface} from "@ypa/types/report-tab";
import {ControlsOf, FormArray, FormGroup} from "@ngneat/reactive-forms";
import {Validators} from "@angular/forms";
import {BaseRelatedClubInterface} from "@ypa/types/related-club";
import {BaseRelatedUserInterface} from "@ypa/types/related-user";
import {FormArrayValidatorHelper} from "@ypa/helpers/custom-validator";
import {TaskInterface} from "@ypa/types/task";
import {FrequencyEnum} from "@ypa/enums/frequency";

@Injectable()
export class TimetableFormService extends AbstractFormService<TimetableInterface> {
    getForm(params: Partial<TimetableInterface>): FormGroup<ControlsOf<Partial<TimetableInterface>>> {
        let controls = this.getIdControlIfExist(params);

        if (params.timeStart) {
            controls = {
                ...controls,
                ...this.getTimeStartControl(params)
            }
        }

        return this.fb.group({
            ...controls,
            ...{
                title: this.fb.control(params.title, [Validators.required]),
                description: this.fb.control(params.description, [Validators.required]),
                duration: this.fb.control(params.duration, [Validators.required]),
                frequency: this.fb.control(params.frequency ? params.frequency : FrequencyEnum.day, [Validators.required]),
                interval: this.fb.control(params.interval != null ? params.interval : 1, [Validators.required, Validators.min(1), Validators.max(365)]),
                enabled: this.fb.control(params.enabled !== null && typeof params.enabled !== 'undefined' ? params.enabled : true),
                dateStart: this.fb.control(params.dateStart, [Validators.required]),
                timeStart: this.fb.control(params.timeStart, [Validators.required]),
                dateEnd: this.fb.control(params.dateStart, [Validators.required]),
                clubs: this.getRelatedClubsFormArray(params.clubs),
                athletes: this.getRelatedAthletesFormArray(params.athletes)
            }
        }) as FormGroup<Partial<ControlsOf<CommonReportTabInterface>>>;
    }

    getTimeStartControl(params: Partial<TaskInterface>) {
        return {
            timeStart: this.fb.control(params.timeStart, [Validators.required]),
        }
    }

    addTimeStartControl(form: FormGroup<ControlsOf<Partial<TaskInterface>>>, time: string) {
        form.addControl('timeStart', this.fb.control(time, [Validators.required]));
    }

    removeTimeStartControl(form: FormGroup<ControlsOf<Partial<TaskInterface>>>) {
        form.removeControl('timeStart');
    }

    getRelatedClubsFormArray(clubs: BaseRelatedClubInterface[] | undefined) {
        return new FormArray(!clubs ? [] : clubs.map(c => this.getRelatedClubForm(c.clubId), FormArrayValidatorHelper.minLength(1)));
    }

    getRelatedAthletesFormArray(athletes: BaseRelatedUserInterface[] | undefined) {
        return new FormArray(!athletes ? [] : athletes.map(a => this.getRelatedAthleteForm(a.userId)), FormArrayValidatorHelper.minLength(1))
    }

    getRelatedClubForm(clubId: number): FormGroup<ControlsOf<BaseRelatedClubInterface>> {
        return this.fb.group({
            clubId: this.fb.control(clubId, [Validators.required])
        });
    }

    getRelatedAthleteForm(userId: number): FormGroup<ControlsOf<BaseRelatedUserInterface>> {
        return this.fb.group({
            userId: this.fb.control(userId, [Validators.required])
        });
    }
}
