import {Injectable} from "@angular/core";
import {
    AbstractEntityClubRelationWithEnableAllRepositoryService
} from "@ypa/data-access/abstract-repository-services";
import {
    BaseSportClubInterface,
    SportClubInterface,
    SportClubParams,
    SportClubEnableInterface
} from "@ypa/types/sport-club";
import {HttpClient} from "@angular/common/http";
import {SportClubRepository} from "@ypa/state-management/shared/sport-club";
import {environment} from "@ypa/constants/environments";
import {Observable, throwError} from "rxjs";
import { EntityListInterface } from "@ypa/types/base-entity";

@Injectable({providedIn: "root"})
export class SportClubService extends AbstractEntityClubRelationWithEnableAllRepositoryService<SportClubInterface, BaseSportClubInterface, SportClubParams, SportClubEnableInterface> {

    constructor(
        private http: HttpClient,
        private sportClubRepository: SportClubRepository
    ) {
        super(sportClubRepository);
    }

    protected override enableForAllReq(form: SportClubEnableInterface): Observable<SportClubInterface[]> {
        return this.http.post<SportClubInterface[]>(`${environment.apiUrl}/sport-club/enabled`, form);
    }

    protected getAllReq(): Observable<SportClubInterface[]> {
        return this.http.get<SportClubInterface[]>(`${environment.apiUrl}/sport-club`);
    }

    protected createReq(form: Partial<SportClubInterface> | BaseSportClubInterface): Observable<SportClubInterface> {
        return this.http.post<SportClubInterface>(`${environment.apiUrl}/sport-club`, form);
    }

    protected getByClubIdReq(clubId: number): Observable<SportClubInterface[]> {
        const params = {
            clubId
        }
        return this.http.get<SportClubInterface[]>(`${environment.apiUrl}/sport-club`, {params});
    }

    protected getByIdReq(id: number): Observable<SportClubInterface> {
        return throwError(() => new Error('Get club sport by id api is not implemented'));
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<SportClubInterface[]>(`${environment.apiUrl}/sport-club/${id}`);
    }

    protected updateReq(id: number, form: Partial<SportClubInterface>): Observable<SportClubInterface> {
        return throwError(() => new Error('Update club sport api is not implemented'));
    }

    protected getByReq(params: SportClubParams): Observable<SportClubInterface[]> {
        return this.http.get<SportClubInterface[]>(`${environment.apiUrl}/sport-club`, {params: params as {}});
    }

    protected updateListReq(list: EntityListInterface<SportClubInterface>): Observable<EntityListInterface<SportClubInterface>> {
        throw new Error("Method not implemented.");
    }
}
