import {Injectable} from "@angular/core";
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {BaseTimetableInterface, TimetableInterface} from "@ypa/types/timetable";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";

const name = 'TIMETABLE';

@Injectable({providedIn: "root"})
export class TimetableRepository extends AbstractEntityRepository<TimetableInterface, BaseTimetableInterface> {
    protected store= createStore(
        {name},
        withEntities<TimetableInterface>()
    );
}
