import {
    AbstractEntityWithClubRelationRepository
} from "@ypa/state-management/shared/abstract";
import {UserDashboardMetricInterface, UserDashboardMetricParams} from "@ypa/types/user-dashboard-metric";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";
import {Injectable} from "@angular/core";
import {UserDashboardMetricClubService} from "@ypa/data-access/user-dashboard-metric-club";
import {
    BaseUserDashboardMetricClubInterface,
    UserDashboardMetricClubInterface, UserDashboardMetricClubParams
} from "@ypa/types/user-dashboard-metric-club";

const name = 'USER_DASHBOARD_METRICS';

@Injectable({providedIn: "root"})
export class UserDashboardMetricRepository extends AbstractEntityWithClubRelationRepository<UserDashboardMetricInterface, UserDashboardMetricClubInterface, BaseUserDashboardMetricClubInterface, UserDashboardMetricParams, UserDashboardMetricClubParams> {
    protected readonly store = createStore({name}, withEntities<UserDashboardMetricInterface>());

    constructor(
        private userDashboardMetricClubService: UserDashboardMetricClubService
    ) {
        super(userDashboardMetricClubService);
    }

    protected override isMatchedEntityWithClubRelation(clubRelations: UserDashboardMetricClubInterface, obj: UserDashboardMetricInterface): boolean {
        return clubRelations.userDashboardMetricId === obj.id;
    }
}
