import {inject, Injectable} from "@angular/core";
import {AbstractFormService} from "@ypa/data-access/abstract-form";
import {FormInterface} from "@ypa/types/form";
import {Validators} from "@angular/forms";
import {ControlsOf, FormGroup} from "@ngneat/reactive-forms";
import {FormTypeEnum} from "@ypa/enums/form-type";
import {LanguageInterface} from "@ypa/types/language";
import {LanguageFormService} from "@ypa/data-access/language";

@Injectable()
export class FormFormService extends AbstractFormService<FormInterface> {

    private languageFormService = inject(LanguageFormService);

    getForm(params: Partial<FormInterface>, uiLanguages?: LanguageInterface[]): FormGroup<ControlsOf<Partial<FormInterface>>> {
        let form = {};

        if (params.id !== null && typeof params.id !== "undefined") {
            form = {
                id: this.fb.control(params.id, [Validators.required]),
            }
        }

        return this.fb.group({
            ...form,
            ...{
                nameTranslationField: this.fb.group(this.languageFormService.getTranslationControls(params.nameTranslationField)),
                descriptionTranslationField: this.fb.group(this.languageFormService.getTranslationControls(params.descriptionTranslationField, false)),
                ignoreTextTranslationField: this.fb.group(this.languageFormService.getTranslationControls(params.ignoreTextTranslationField, false)),
                // name: this.fb.control(params.nameTranslationField ? params.nameTranslationField : '', [Validators.required]),
                // ignoreText: this.fb.control(params.ignoreTextTranslationField ? params.ignoreTextTranslationField : ''),
                icon: this.fb.control(params.icon ? params.icon : '', [Validators.required]),
                type: this.fb.control(params.type ? params.type : FormTypeEnum.always, [Validators.required]),
                ownerClubId: this.fb.control(params.ownerClubId ? params.ownerClubId : null)
            }
        });
    }

    addParentForm(form: FormGroup<ControlsOf<Partial<FormInterface>>>, value: number) {
        form.addControl('parentId', this.fb.control(value), {
            emitEvent: false
        });
    }

    removeParentForm(form: FormGroup<ControlsOf<Partial<FormInterface>>>) {
        form.removeControl('parentId');
    }
}
