import {Injectable} from '@angular/core';
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {BaseCoachDashboardInterface, CoachDashboardInterface, CoachDashboardParams} from "@ypa/types/coach-dashboard";
import {HttpClient} from "@angular/common/http";
import {CoachDashboardRepository} from "@ypa/state-management/shared/coach-dashboard";
import {Observable, tap} from "rxjs";
import {environment} from "@ypa/constants/environments";
import {EntityListInterface} from '@ypa/types/base-entity';

@Injectable({providedIn: "root"})
export class CoachDashboardService extends AbstractEntityRepositoryServices<CoachDashboardInterface, BaseCoachDashboardInterface, CoachDashboardParams> {

    constructor(
        private http: HttpClient,
        private coachDashboardRepository: CoachDashboardRepository
    ) {
        super(coachDashboardRepository)
    }

    getByRoleClubId(params: CoachDashboardParams): Observable<CoachDashboardInterface[]> {
        return this.getByRoleClubIdReq(params).pipe(
            tap(coachDashboard => this.coachDashboardRepository.upsert(coachDashboard))
        )
    }

    private getByRoleClubIdReq(params: CoachDashboardParams): Observable<CoachDashboardInterface[]> {
        return this.http.get<CoachDashboardInterface[]>(`${environment.apiUrl}/coach-dashboard/role/${params.roleId}/club/${params.clubId}`);
    }


    protected createReq(form: Partial<CoachDashboardInterface> | BaseCoachDashboardInterface): Observable<CoachDashboardInterface> {
        return this.http.post<CoachDashboardInterface>(`${environment.apiUrl}/coach-dashboard`, form);
    }

    protected getAllReq(): Observable<CoachDashboardInterface[]> {
        return this.http.get<CoachDashboardInterface[]>(`${environment.apiUrl}/coach-dashboard`);
    }

    protected getByIdReq(id: number): Observable<CoachDashboardInterface> {
        return this.http.get<CoachDashboardInterface>(`${environment.apiUrl}/coach-dashboard/${id}`);
    }

    protected getByReq(params: CoachDashboardParams): Observable<CoachDashboardInterface[]> {
        return this.http.get<CoachDashboardInterface[]>(`${environment.apiUrl}/coach-dashboard/role/${params.roleId}/club/${params.clubId}`);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/coach-dashboard/${id}`);
    }

    protected updateReq(id: number, form: Partial<CoachDashboardInterface>): Observable<CoachDashboardInterface> {
        return this.http.patch<CoachDashboardInterface>(`${environment.apiUrl}/coach-dashboard/${id}`, form);
    }

    protected override updateListReq(list: EntityListInterface<CoachDashboardInterface>): Observable<EntityListInterface<CoachDashboardInterface>> {
        throw new Error('Method not implemented.');
    }
}
