import {Observable} from "rxjs";
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {AbstractEntityWithActiveIdRepository} from "@ypa/state-management/shared/abstract";
import {BaseEntityInterface, BaseEntityParams} from "@ypa/types/base-entity";

export abstract class AbstractEntityWithActiveIdRepositoryService<T extends BaseEntityInterface, TBase, TParams extends BaseEntityParams> extends AbstractEntityRepositoryServices<T, TBase, TParams>{
    active$: Observable<T | undefined > | null;

    protected constructor(protected repositoryWithActiveId: AbstractEntityWithActiveIdRepository<T, TParams>) {
        super(repositoryWithActiveId);
        this.active$ = repositoryWithActiveId.active$;
    }

    setActive(id: number) {
        this.repositoryWithActiveId.setActive(id);
    }

    resetActive() {
        this.repositoryWithActiveId.resetActive();
    }
}
