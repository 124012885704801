import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {JsonLoaderService} from '@ypa/i18n';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ErrorInterceptor} from './interceptors/error.interceptor';
import {AuthInterceptor} from '@ypa/interceptors/auth';
import {LanguageCurrentService} from '@ypa/data-access/language';
import {of, take} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {LanguageCodeEnum} from '@ypa/enums/language-code';
import { ANALYTICS_SERVICE_TOKEN, MixpanelAnalyticsService } from '@ypa/libs/analytics';

import myLocaleDk from '@angular/common/locales/da';
import myLocaleEn from '@angular/common/locales/en';

import {IMAGE_LOADER, ImageLoaderConfig, registerLocaleData} from '@angular/common';
import {UserAuthService} from '@ypa/data-access/user';
import {UserAuthRepository} from '@ypa/state-management/shared/auth';
import {environment} from "@ypa/constants/environments";

registerLocaleData(myLocaleDk);
registerLocaleData(myLocaleEn);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: JsonLoaderService,
            },
            defaultLanguage: localStorage.getItem('locale')
                ? localStorage.getItem('locale')
                : LanguageCodeEnum.Danish,
        }),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (
                userAuthService: UserAuthService,
                userAuthRepository: UserAuthRepository,
                languageCurrentService: LanguageCurrentService
            ) => {
                return () =>
                    userAuthService.get(true).pipe(
                        take(1),
                        catchError(() => of(null)),
                        switchMap((userAuth) =>
                            languageCurrentService.getLanguageList().pipe(
                                take(1),
                                catchError(() => of(null)),
                                switchMap(() => of(userAuth))
                            )
                        )
                    );
            },
            deps: [UserAuthService, UserAuthRepository, LanguageCurrentService],
            multi: true,
        },
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {
            provide: IMAGE_LOADER,
            useValue: (config: ImageLoaderConfig) => {
                return `${environment.contentApiUrl}${config.src}`;
            },
        },
        { provide: ANALYTICS_SERVICE_TOKEN, useClass: MixpanelAnalyticsService }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
