export enum VolumeImperialUnitsEnum {
    // gill = "GILL",
    // pint = "PINT",
    // quart = "QUART",
    // gallon = "GALLON"
    pint = "PT",
    quart = "QT",
    gallon = "GAL"
    //...
}
