import {Injectable} from '@angular/core';
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";
import {CoachDashboardInterface} from "@ypa/types/coach-dashboard";
import {CoachDashboardParams} from "@ypa/types/coach-dashboard";

const name = 'COACH_DASHBOARD';

@Injectable({
    providedIn: 'root'
})
export class CoachDashboardRepository extends AbstractEntityRepository<CoachDashboardInterface, CoachDashboardParams> {
    protected readonly store = createStore({name}, withEntities<CoachDashboardInterface>());
}
