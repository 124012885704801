import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {Observable} from "rxjs";
import {selectManyByPredicate} from "@ngneat/elf-entities";
import {BaseEntityInterface, BaseEntityParams} from "@ypa/types/base-entity";

export abstract class AbstractEntityClubRelationRepository<T extends BaseEntityInterface, TParams extends BaseEntityParams> extends AbstractEntityRepository<T, TParams> {
    getByClubId(id: number): Observable<T[]> {
        return this.store.pipe(selectManyByPredicate(x => x.clubId === id));
    }
}
