import {Injectable} from '@angular/core';
import {createStore} from '@ngneat/elf';
import {
    withEntities,
} from '@ngneat/elf-entities';
import {FormInterface, FormParams} from '@ypa/types/form';
import {
    AbstractEntityWithClubRelationRepository
} from "@ypa/state-management/shared/abstract";
import {FormClubService} from "@ypa/data-access/form-club";
import {BaseFormClubInterface, FormClubInterface, FormClubParams} from "@ypa/types/form-club";

const name = 'FORMS';

@Injectable({providedIn: "root"})
export class FormRepository extends AbstractEntityWithClubRelationRepository<FormInterface, FormClubInterface, BaseFormClubInterface, FormParams, FormClubParams> {
    protected readonly store = createStore(
        {name},
        withEntities<FormInterface>()
    );

    constructor(
        private formClubService: FormClubService
    ) {
        super(formClubService);
    }

    protected isMatchedEntityWithClubRelation(clubRelations: FormClubInterface, obj: FormInterface): boolean {
        return clubRelations.formId === obj.id;
    }
}
