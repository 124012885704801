import {select} from "@ngneat/elf";
import {Observable} from "rxjs";

export abstract class AbstractPropertyRepository<T> {
    protected abstract readonly store: any;

    get isEmpty(): boolean {
        return this.store.isEmpty;
    }

    get data$(): Observable<T> {
        return this.store.pipe(select((state: { data: T }) => state.data));
    }

    update(value: T) {
        this.store.update(() => ({data: value}));
    }

    clear() {
        this.store.reset();
    }

    getSnapshot(): T {
        return (this.store.getValue() as { data: T }).data;
    }
}
