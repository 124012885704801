import {ControlsOf, FormBuilder, FormControl, FormGroup} from "@ngneat/reactive-forms";
import {inject, Injector} from "@angular/core";
import {BaseEntityInterface} from "@ypa/types/base-entity";
import {Validators} from "@angular/forms";
import {LanguageInterface} from "@ypa/types/language";
import {LanguageFormService} from "@ypa/data-access/language";
import {CommonReportTabInterface} from "@ypa/types/report-tab";

export abstract class AbstractFormService<T extends BaseEntityInterface> {

    protected fb = inject(FormBuilder);

    abstract getForm(params: Partial<T>): FormGroup<ControlsOf<Partial<T>>>;

    getIdControlIfExist(params: Partial<T>): { id?: FormControl<number> } {
        let form = {};

        if (params.id !== null && typeof params.id !== "undefined") {
            form = {
                id: this.fb.control(params.id, [Validators.required]),
            }
        }

        return form;
    }
}
