import {Injectable} from "@angular/core";
import {AbstractEntityClubRelationRepository} from "@ypa/state-management/shared/abstract";
import {SportClubInterface, SportClubParams} from "@ypa/types/sport-club";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";

const name = 'CLUB_SPORT';

@Injectable({providedIn: "root"})
export class SportClubRepository extends AbstractEntityClubRelationRepository<SportClubInterface, SportClubParams> {
    protected readonly store  = createStore(
        {name,},
        withEntities<SportClubInterface>()
    );
}
