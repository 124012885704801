import {Injectable} from '@angular/core';
import {createStore} from '@ngneat/elf';
import {withEntities} from '@ngneat/elf-entities';
import {FormClubInterface, FormClubParams} from '@ypa/types/form-club';
import {AbstractEntityClubRelationRepository} from "@ypa/state-management/shared/abstract";

const name = 'FORM_CLUB';

@Injectable({providedIn: "root"})
export class FormClubRepository extends AbstractEntityClubRelationRepository<FormClubInterface, FormClubParams> {
    protected readonly store = createStore(
        {name},
        withEntities<FormClubInterface>()
    );
}
