import {Injectable} from "@angular/core";
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";
import {MetricHintTextInterface, MetricHintTextParams} from "@ypa/types/metric-hint-text";

const name = 'METRIC_HINT_TEXT'

@Injectable({providedIn: "root"})
export class MetricHintTextRepository extends AbstractEntityRepository<MetricHintTextInterface, MetricHintTextParams> {
    protected readonly store = createStore(
        {name},
        withEntities<MetricHintTextInterface>()
    );
}
