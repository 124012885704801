import {
    ForceUnitsEnum,
    LengthImperialUnitsEnum,
    LengthMetricUnitsEnum,
    MeasurementSystemEnum,
    MeasureTypeEnum,
    QuantityUnitsEnum,
    TemperatureUnitsEnum, TimeUnitsEnum, VolumeImperialUnitsEnum, VolumeMetricUnitsEnum, WeightImperialUnitsEnum,
    WeightMetricUnitsEnum
} from "@ypa/enums/units";

export class MeasureAccordance {
    constructor(
        public measureType: MeasureTypeEnum,
        public accordance: AccordanceInterface
    ) {
    }

    getByMeasureSystem(measureSystem: MeasurementSystemEnum): typeof QuantityUnitsEnum | typeof TimeUnitsEnum | typeof TemperatureUnitsEnum | typeof WeightMetricUnitsEnum | typeof LengthMetricUnitsEnum | typeof VolumeMetricUnitsEnum | typeof WeightImperialUnitsEnum | typeof LengthImperialUnitsEnum | typeof VolumeImperialUnitsEnum | typeof ForceUnitsEnum {
        if (measureSystem === MeasurementSystemEnum.imperial) {
            return this.accordance.imperial;
        }
        return this.accordance.metric;
    }
}

export interface AccordanceInterface {
    metric: typeof QuantityUnitsEnum | typeof TimeUnitsEnum | typeof TemperatureUnitsEnum | typeof WeightMetricUnitsEnum | typeof LengthMetricUnitsEnum | typeof VolumeMetricUnitsEnum | typeof ForceUnitsEnum,
    imperial: typeof QuantityUnitsEnum | typeof TimeUnitsEnum | typeof TemperatureUnitsEnum | typeof WeightImperialUnitsEnum | typeof LengthImperialUnitsEnum | typeof VolumeImperialUnitsEnum | typeof ForceUnitsEnum
}
