import {Injectable} from "@angular/core";
import {AbstractReportTabWithRangeFormService} from "@ypa/data-access/abstract-form";
import {ControlsOf, FormControl, FormGroup} from "@ngneat/reactive-forms";
import {BaseWidgetColorRangeInterface, BaseWidgetMetricConfigInterface, CommonWidgetInterface} from "@ypa/types/widget";
import {Validators} from "@angular/forms";
import {PresentationTypeEnum} from "@ypa/enums/presentation-type";
import {TypeOfStatisticEnum} from "@ypa/enums/type-of-statistic";
import {SegmentBodyEnum} from "@ypa/enums/segment-body";
import {ReportTabWidgetMetricTypeEnum} from "@ypa/enums/report-tab-widget-metric-type";
import {CustomValidatorsHelper} from "@ypa/helpers/custom-validator";
import {StatisticItemTypeEnum} from "@ypa/enums/statistic-item-type";
import {ChartDirectionEnum} from "@ypa/enums/chart-direction";
import {ChartMainAxisTypeEnum} from "@ypa/enums/chart-main-axis-type";
import {SectorTypeEnum} from "@ypa/enums/sector-type";
import {PieDataTypeEnum} from "@ypa/enums/pie-data-type";
import {AvailabilityDatePresentationEnum} from "@ypa/enums/availability-date-presentation";
import * as moment from "moment";
import {CommonReportTabInterface} from "@ypa/types/report-tab";
import {ReportTabTypeEnum} from "enums/report-tab-type";
import {AggregateTypeEnum} from "@ypa/enums/aggregate-type";
import {WidgetTypeEnum} from "@ypa/enums/widget-type";

@Injectable({providedIn: "root"})
export class ReportTabWidgetFormService extends AbstractReportTabWithRangeFormService<CommonWidgetInterface> {

    getForm(params: Partial<CommonWidgetInterface>): FormGroup<ControlsOf<Partial<CommonWidgetInterface>>> {
        const controls = this.getIdControlIfExist(params);
        return this.fb.group({
            ...controls,
            ...{
                name: this.fb.control(params.name, [Validators.required]),
                type: this.fb.control(params.type, [Validators.required]),
                tabId: this.fb.control(params.tabId, [Validators.required]),
                columns: this.fb.control(params.cols),
                rows: this.fb.control(params.rows),
                position: this.fb.control(params.x)
            }
        }) as FormGroup<ControlsOf<Partial<CommonWidgetInterface>>>;
    }

    getBaseWidgetMetricForm(widgetMetric: BaseWidgetMetricConfigInterface, reportTab: CommonReportTabInterface, widgetType: WidgetTypeEnum): FormGroup<ControlsOf<BaseWidgetMetricConfigInterface>> {
        const controls: ControlsOf<Partial<BaseWidgetMetricConfigInterface>> = {};

        if (widgetMetric && widgetMetric.id != null) {
            controls.id = this.fb.control(widgetMetric.id, [Validators.required]);
        }

        if (reportTab.type !== ReportTabTypeEnum.profile || widgetType === WidgetTypeEnum.pieChart) {
            controls.groupCalculationType = this.fb.control(widgetMetric && widgetMetric.groupCalculationType ? widgetMetric.groupCalculationType : AggregateTypeEnum.average, [Validators.required])
        }

        const widgetMetricForm = this.fb.group<ControlsOf<BaseWidgetMetricConfigInterface>>({
            ...controls,
            metricId: this.fb.control<number>(widgetMetric && widgetMetric.metricId != null ? widgetMetric.metricId : undefined, [Validators.required]),
            dayCalculationType: this.fb.control(widgetMetric && widgetMetric.dayCalculationType ? widgetMetric.dayCalculationType : AggregateTypeEnum.average, [Validators.required]),
            dateRangeCalculationType: this.fb.control(widgetMetric && widgetMetric.dateRangeCalculationType ? widgetMetric.dateRangeCalculationType : AggregateTypeEnum.average, [Validators.required]),
        }) as FormGroup<ControlsOf<BaseWidgetMetricConfigInterface>>;

        if (widgetMetric && widgetMetric.segment) {
            this.addSegmentControl(widgetMetricForm, widgetMetric.segment)
        }

        return widgetMetricForm;
    }

    getColorCodeRangeForm(widgetColorRange: BaseWidgetColorRangeInterface): FormGroup<ControlsOf<BaseWidgetColorRangeInterface>> {
        const controls: ControlsOf<Partial<BaseWidgetColorRangeInterface>> = {};

        if (widgetColorRange && widgetColorRange.id != null) {
            controls.id = this.fb.control(widgetColorRange.id, [Validators.required])
        }

        return this.fb.group<ControlsOf<BaseWidgetColorRangeInterface>>({
            ...controls,
            from: this.fb.control<number>(widgetColorRange && widgetColorRange.from != null ? widgetColorRange.from : undefined, [Validators.required]),
            to: this.fb.control<number>(widgetColorRange && widgetColorRange.to != null ? widgetColorRange.to : undefined, [Validators.required]),
            color: this.fb.control<string>(widgetColorRange && widgetColorRange.color != null ? widgetColorRange.color : undefined, [Validators.required, CustomValidatorsHelper.hexColor()]),
            position: this.fb.control<number>(widgetColorRange && widgetColorRange.position != null ? widgetColorRange.position : 0, [Validators.required])
        }, {validators: [CustomValidatorsHelper.controlAGreaterControlB('to', 'from')]}) as FormGroup<ControlsOf<BaseWidgetColorRangeInterface>>;
    }


    //add control functions impure--------------------
    addTypeControl(metricForm: FormGroup<ControlsOf<BaseWidgetMetricConfigInterface>>, type: ReportTabWidgetMetricTypeEnum, emitEvent = false) {
        metricForm.addControl(
            'type',
            new FormControl<ReportTabWidgetMetricTypeEnum>(
                type,
                [Validators.required]
            ),
            {emitEvent}
        )
    }

    addMinControl(metricForm: FormGroup<ControlsOf<BaseWidgetMetricConfigInterface>>, min: number, emitEvent = false) {
        metricForm.addControl(
            'min',
            new FormControl<number>(
                min != null ? min : undefined,
                [Validators.required]
            ),
            {emitEvent}
        )
    }

    addMaxControl(metricForm: FormGroup<ControlsOf<BaseWidgetMetricConfigInterface>>, max: number, emitEvent = false) {
        metricForm.addControl(
            'max',
            new FormControl<number>(
                max != null ? max : undefined,
                [Validators.required]
            ),
            {emitEvent}
        )
    }

    addSegmentControl(metricForm: FormGroup<ControlsOf<BaseWidgetMetricConfigInterface>>, segment: SegmentBodyEnum, emitEvent = false) {
        metricForm.addControl(
            'segment',
            new FormControl<SegmentBodyEnum>(
                segment ? segment : undefined,
                [Validators.required]
            ),
            {emitEvent}
        )
    }

    addPresentationTypeControl(metricForm: FormGroup<ControlsOf<BaseWidgetMetricConfigInterface>>, presentationType: PresentationTypeEnum, emitEvent = false) {
        metricForm.addControl(
            'presentationType',
            new FormControl<PresentationTypeEnum>(
                presentationType ? presentationType : PresentationTypeEnum.line,
                [Validators.required]
            ),
            {emitEvent}
        )
    }

    addTypeOfStatisticControl(metricForm: FormGroup<ControlsOf<BaseWidgetMetricConfigInterface>>, typeOfStatistic: TypeOfStatisticEnum, emitEvent = false) {
        metricForm.addControl(
            'typeOfStatistic',
            new FormControl<TypeOfStatisticEnum>(
                typeOfStatistic ? typeOfStatistic : TypeOfStatisticEnum.reportValue,
                [Validators.required]
            ),
            {emitEvent}
        )
    }

    addStatisticItemTypeControl(widgetForm: FormGroup<ControlsOf<CommonWidgetInterface>>, statisticItemType: StatisticItemTypeEnum, emitEvent = false) {
        widgetForm.addControl(
            'statisticItemType',
            new FormControl<StatisticItemTypeEnum>(
                statisticItemType ? statisticItemType : StatisticItemTypeEnum.athletes,
                [Validators.required]
            ),
            {emitEvent}
        )
    }

    addChartDirectionControl(widgetForm: FormGroup<ControlsOf<CommonWidgetInterface>>, chartDirection: ChartDirectionEnum, emitEvent = false) {
        widgetForm.addControl(
            'chartDirection',
            new FormControl<ChartDirectionEnum>(
                chartDirection ? chartDirection : ChartDirectionEnum.horizontal,
                [Validators.required]
            ),
            {emitEvent}
        );
    }

    addChartMainAxisTypeControl(widgetForm: FormGroup<ControlsOf<CommonWidgetInterface>>, chartMainAxisType: ChartMainAxisTypeEnum, emitEvent = false) {
        widgetForm.addControl(
            'chartMainAxisType',
            new FormControl<ChartMainAxisTypeEnum>(
                chartMainAxisType ? chartMainAxisType : ChartMainAxisTypeEnum.days,
                [Validators.required]
            ),
            {emitEvent}
        );
    }

    addIsInjuryControl(widgetForm: FormGroup<ControlsOf<CommonWidgetInterface>>, isInjury: boolean, emitEvent = false) {
        widgetForm.addControl(
            'isInjury',
            new FormControl<boolean>(
                isInjury ? isInjury : false,
                [Validators.required]
            ),
            {emitEvent}
        );
    }

    addAxisXMetricIdControl(widgetForm: FormGroup<ControlsOf<CommonWidgetInterface>>, axisXMetricId: number, emitEvent = false) {
        widgetForm.addControl(
            'axisXMetricId',
            new FormControl<number>(
                axisXMetricId != null ? axisXMetricId : undefined,
                [Validators.required]
            ),
            {emitEvent}
        );
    }

    addAxisYMetricIdControl(widgetForm: FormGroup<ControlsOf<CommonWidgetInterface>>, axisYMetricId: number, emitEvent = false) {
        widgetForm.addControl(
            'axisYMetricId',
            new FormControl<number>(
                axisYMetricId != null ? axisYMetricId : undefined,
                [Validators.required]
            ),
            {emitEvent}
        );
    }

    addAxisXMetricSegmentControl(widgetForm: FormGroup<ControlsOf<CommonWidgetInterface>>, axisXMetricSegment: SegmentBodyEnum, emitEvent = false) {
        widgetForm.addControl(
            'axisXMetricSegment',
            new FormControl<SegmentBodyEnum>(
                axisXMetricSegment != null ? axisXMetricSegment : undefined,
                [Validators.required]
            ),
            {emitEvent}
        );
    }

    addAxisYMetricSegmentControl(widgetForm: FormGroup<ControlsOf<CommonWidgetInterface>>, axisYMetricSegment: SegmentBodyEnum, emitEvent = false) {
        widgetForm.addControl(
            'axisYMetricSegment',
            new FormControl<SegmentBodyEnum>(
                axisYMetricSegment != null ? axisYMetricSegment : undefined,
                [Validators.required]
            ),
            {emitEvent}
        );
    }

    addSectorTypeControl(widgetForm: FormGroup<ControlsOf<CommonWidgetInterface>>, sectorType: SectorTypeEnum, emitEvent = false) {
        widgetForm.addControl(
            'sectorType',
            new FormControl<SectorTypeEnum>(
                sectorType ? sectorType : SectorTypeEnum.months,
                [Validators.required]
            ),
            {emitEvent}
        );
    }

    addPieDataTypeControl(widgetForm: FormGroup<ControlsOf<CommonWidgetInterface>>, dataType: PieDataTypeEnum, emitEvent = false) {
        widgetForm.addControl(
            'dataType',
            new FormControl<PieDataTypeEnum>(
                dataType ? dataType : PieDataTypeEnum.metric,
                [Validators.required]
            ),
            {emitEvent}
        );
    }

    addYearControl(widgetForm: FormGroup<ControlsOf<CommonWidgetInterface>>, year: string, emitEvent = false) {
        widgetForm.addControl(
            'year',
            new FormControl<string>(
                year ? year : moment().format('YYYY'),
                [Validators.required]
            ),
            {emitEvent}
        );
    }

    addAvailabilityDatePresentationControl(widgetForm: FormGroup<ControlsOf<CommonWidgetInterface>>, availabilityDatePresentation: AvailabilityDatePresentationEnum, emitEvent = false) {
        widgetForm.addControl(
            'availabilityDatePresentation',
            new FormControl<AvailabilityDatePresentationEnum>(
                availabilityDatePresentation ? availabilityDatePresentation : AvailabilityDatePresentationEnum.percentage,
                [Validators.required]
            ),
            {emitEvent}
        );
    }
}
