import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseMetricReportInterface, MetricReportInterface, MetricReportParams} from '@ypa/types/metric-report';
import {environment} from '@ypa/constants/environments';
import {Observable, throwError} from 'rxjs';
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {BaseMetricInterface} from "@ypa/types/metric";
import {MetricReportRepository} from "@ypa/state-management/shared/metric-report";
import {EntityListInterface} from "@ypa/types/base-entity";

@Injectable({providedIn: "root"})
export class MetricReportService extends AbstractEntityRepositoryServices<MetricReportInterface, BaseMetricInterface, MetricReportParams> {
    constructor(
        private http: HttpClient,
        private metricReportRepository: MetricReportRepository
    ) {
        super(metricReportRepository);
    }

    protected getAllReq(): Observable<MetricReportInterface[]> {
        return throwError(() => new Error('Get all metric reports api not implemented'))
    }

    protected createReq(form: BaseMetricReportInterface | Partial<MetricReportInterface>): Observable<MetricReportInterface> {
        return this.http.post<MetricReportInterface>(`${environment.apiUrl}/metric-report`, form);
    }

    protected updateReq(id: number, form: Partial<MetricReportInterface>): Observable<MetricReportInterface> {
        return this.http.patch<MetricReportInterface>(`${environment.apiUrl}/metric-report/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/metric-report/${id}`);
    }

    protected getByIdReq(id: number): Observable<MetricReportInterface> {
        return this.http.get<MetricReportInterface>(`${environment.apiUrl}/metric-report/${id}`);
    }

    protected getByReq(params: MetricReportParams): Observable<MetricReportInterface[]> {
        return this.http.get<MetricReportInterface[]>(`${environment.apiUrl}/metric-report`, {params: params as {}})
    }

    protected updateListReq(list: EntityListInterface<MetricReportInterface>): Observable<EntityListInterface<MetricReportInterface>> {
        throw new Error('Method not implemented.');
    }
}
