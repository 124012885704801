import {MeasureAccordance} from "./measure-accordance";
import {LanguageCodeEnum} from "@ypa/enums/language-code";

export interface TitleValueInterface {
    title: string;
    value: string;
}

export interface EnvironmentsInterface {
    production: boolean,
    preProduction: boolean,
    apiUrl: string,
    contentApiUrl?: string,
    endpointMainPrefix?: string,
    endpointAdditionalPrefix?: string,
    roles: {
        coach: number,
        athlete: number,
    },
    defaultMetrics: {
        bodyMapMorningPain: number,
        bodyMapMorningSoreness: number,
        bodyMapTrainingPain: number,
        bodyMapTrainingSoreness: number,
        urine: number,
    },
    calculationTypes: {
        group: TitleValueInterface,
        sum: TitleValueInterface,
        avg: TitleValueInterface,
        value: TitleValueInterface,
    }
    calculationPositions: {
        left: number,
        right: number
    }
    calculationOperators: {
        add: TitleValueInterface,
        subtract: TitleValueInterface,
        multiply: TitleValueInterface,
        divide: TitleValueInterface,
        modulus: TitleValueInterface,
    }
    bodyMetricIds: {
        morningPain: number,
        morningSoreness: number,
        trainingPain: number,
        trainingSoreness: number
    }
    currentClubDropdown: {
        noClubId: number,
        noClubText: string
    }
    currentRoleDropdown: {
        noRoleId: number,
        noRoleText: string
    },
    measureAccordance: MeasureAccordance[],
    defaultLanguage: LanguageCodeEnum,
    allowedLanguages: LanguageCodeEnum[],
}
