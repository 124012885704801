import {inject, Injectable} from "@angular/core";
import {ControlsOf, FormControl, FormGroup} from "@ngneat/reactive-forms";
import {TaskInterface} from "@ypa/types/task";
import {Validators} from "@angular/forms";
import {BaseTaskConfigInterface, TaskConfigInterface, TaskWeeklyConfigInterface} from "@ypa/types/task-config";
import {TaskConfigWeekDayEnum} from "@ypa/enums/task-config-week-day";
import {AbstractFormService} from "@ypa/data-access/abstract-form";
import {LanguageFormService} from "@ypa/data-access/language";

@Injectable()
export class TaskFormService extends AbstractFormService<TaskInterface> {

    protected languageFormService = inject(LanguageFormService);

    getForm(params: Partial<TaskInterface>): FormGroup<Partial<ControlsOf<TaskInterface>>> {
        let form = {};

        if (params.id !== null && typeof params.id !== "undefined") {
            form = {
                id: this.fb.control(params.id, [Validators.required]),
            }
        }

        if (params.timeStart) {
            form = {
                ...form,
                ...this.getTimeStartControl(params)
            }
        }

        return this.fb.group({
            ...form,
            ...{
                nameTranslationField: this.fb.group(this.languageFormService.getTranslationControls(params.nameTranslationField)),
                clubId: this.fb.control(params.clubId, [Validators.required]),
                formId: this.fb.control(params.formId, [Validators.required]),
                sportId: this.fb.control(params.sportId),
                dateStart: this.fb.control(params.dateStart, [Validators.required]),
                dateEnd: this.fb.control(params.dateEnd),
                gender: this.fb.control(params.gender),
                frequency: this.fb.control(params.frequency, [Validators.required]),
                interval: this.fb.control(params.interval, [Validators.required, Validators.min(1), Validators.max(365)]),
                enabled: this.fb.control(params.enabled !== null && typeof params.enabled !== 'undefined' ? params.enabled : true),
                sendEmail: this.fb.control(params.sendEmail !== null && typeof params.sendEmail !== 'undefined' ? params.sendEmail : false),
                sendPush: this.fb.control(params.sendPush !== null && typeof params.sendPush !== 'undefined' ? params.sendPush : true)
            }
        }) as FormGroup<Partial<ControlsOf<TaskInterface>>>;
    }

    getTaskConfigForm(params: TaskConfigInterface<TaskWeeklyConfigInterface> | null = null): FormGroup<ControlsOf<TaskConfigInterface<TaskWeeklyConfigInterface>>> {
        const enumDays = Object.entries(TaskConfigWeekDayEnum).filter(x => Number(x[1]) >= 0).map(x => x[1]);
        // @ts-ignore
        return this.fb.group({
            configs: this.fb.array<TaskWeeklyConfigInterface>(
                // @ts-ignore
                enumDays.map(day => this.fb.group<TaskWeeklyConfigInterface>({
                    // @ts-ignore
                    weekDay: this.fb.control(day as TaskConfigWeekDayEnum),
                    // @ts-ignore
                    timeList: this.fb.array<string | null>(this.getTaskConfigTimeListFromParamsForDay(params, day))
                }))
            )
        })
    }

    getTimeStartControl(params: Partial<TaskInterface>) {
        return {
            timeStart: this.fb.control(params.timeStart, [Validators.required]),
        }
    }

    addTimeStartControl(form: FormGroup<ControlsOf<Partial<TaskInterface>>>, time: string) {
        form.addControl('timeStart', this.fb.control(time, [Validators.required]));
    }

    removeTimeStartControl(form: FormGroup<ControlsOf<Partial<TaskInterface>>>) {
        form.removeControl('timeStart');
    }

    getTimeConfig(value: string | null = null): FormControl<string | null> {
        return this.fb.control(value ? value : '00:00', [Validators.required]);
    }

    private getTaskConfigTimeListFromParamsForDay(params: TaskConfigInterface<TaskWeeklyConfigInterface>, weekDay: TaskConfigWeekDayEnum): FormControl<string | null>[] {
        if (!params) {
            return [];
        }

        const weekDayConfig = params.configs.find(x => +x.weekDay === weekDay);

        if (!weekDayConfig) {
            return [];
        }

        if (!weekDayConfig.timeList) {
            return [];
        }

        return weekDayConfig.timeList.map(x => this.getTimeConfig(x))
    }
}
