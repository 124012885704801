import {Injectable} from "@angular/core";
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {BaseMetricHintInterface, MetricHintInterface, MetricHintParams} from "@ypa/types/metric-hint";
import {Observable, throwError} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {MetricHintRepository} from "@ypa/state-management/shared/metric-hint";
import {environment} from "@ypa/constants/environments";
import {EntityListInterface} from "@ypa/types/base-entity";


@Injectable({providedIn: "root"})
export class MetricHintService extends AbstractEntityRepositoryServices<MetricHintInterface, BaseMetricHintInterface, MetricHintParams> {

    constructor(
        private http: HttpClient,
        private metricHintRepository: MetricHintRepository
    ) {
        super(metricHintRepository);
    }

    protected getAllReq(): Observable<MetricHintInterface[]> {
        return throwError(() => new Error('Get all metric hints is not implemented'))
    }

    protected createReq(form: BaseMetricHintInterface): Observable<MetricHintInterface> {
        return this.http.post<MetricHintInterface>(`${environment.apiUrl}/metric-hint`, form);
    }

    protected updateReq(id: number, form: Partial<MetricHintInterface>): Observable<MetricHintInterface> {
        return this.http.put<MetricHintInterface>(`${environment.apiUrl}/metric-hint/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/metric-hint/${id}`);
    }

    protected getByIdReq(id: number): Observable<MetricHintInterface> {
        return throwError(() => new Error('Get metric hint by id method is not implemented'))
    }

    protected getByReq(params: MetricHintParams): Observable<MetricHintInterface[]> {
        return this.http.get<MetricHintInterface[]>(`${environment.apiUrl}/metric-hint`, {params: params as {}})
    }

    protected updateListReq(list: EntityListInterface<MetricHintInterface>): Observable<EntityListInterface<MetricHintInterface>> {
        throw new Error("Method not implemented.");
    }
}
