import {inject, Injectable} from '@angular/core';
import {ControlsOf, FormArray, FormBuilder, FormGroup} from "@ngneat/reactive-forms";
import {Validators} from "@angular/forms";
import {BaseTranslationInterface} from "@ypa/types/translation";
import {BaseTranslationFieldInterface} from "@ypa/types/translation-field";
import {LanguageCurrentService} from "./language-current.service";

@Injectable({
    providedIn: 'root'
})
export class LanguageFormService {

    protected fb = inject(FormBuilder);

    constructor(private languageCurrentService: LanguageCurrentService) {
    }

    getTranslationControls(translationField?: BaseTranslationFieldInterface, isRequired = true): ControlsOf<BaseTranslationFieldInterface> {

        const translationFieldData = translationField ? translationField : {id: undefined, translations: []};

        let form = {};

        if (translationFieldData.id !== null && typeof translationFieldData.id !== "undefined") {
            form = {
                id: this.fb.control(translationFieldData.id),
            }
        }

        return {
            ...form,
            translations: this.fb.array(this.getFormTranslationArray(translationFieldData.translations, isRequired))
        }
    }

    getFormArray(): FormArray<Partial<any>> {
        return this.fb.array([]);
    }

    removeEmptyFields(translationField: BaseTranslationFieldInterface): BaseTranslationFieldInterface {
        translationField.translations = translationField.translations.filter((t) => t.text !== null)
        return translationField;
    }

    private getForm(params: Partial<BaseTranslationInterface>, isRequired = false): FormGroup<ControlsOf<BaseTranslationInterface>> {

        let form = {};

        if (params.id !== null && typeof params.id !== "undefined") {
            form = {
                id: this.fb.control(params.id),
            }
        }

        if (params.translationFieldId !== null && typeof params.translationFieldId !== "undefined") {
            form = {
                ...form,
                translationGroupId: this.fb.control(params.translationFieldId),
            }
        }

        if (params.language !== null && typeof params.language !== "undefined") {
            form = {
                ...form,
                language: this.fb.control(params.language),
            }
        }

        return this.fb.group({
            ...form,
            languageId: this.fb.control(params.languageId, isRequired ? [Validators.required] : []),
            text: this.fb.control(params.text, isRequired ? [Validators.required] : []),
        });
    }

    private getFormTranslationArray(translations: BaseTranslationInterface[], isRequired: boolean): FormGroup<ControlsOf<BaseTranslationInterface>>[] {
        const arr: FormGroup<ControlsOf<BaseTranslationInterface>>[] = [];
        this.languageCurrentService.getLanguagesListSnapshot().map((l) => {
            const hasTranslate = translations.find((t) => l.id === t.languageId);
            //could be used if form group should be requred only for default or specyfic language
            // const isDefaultLanguage = l.code === this.languageCurrentService.getCurrentLanguageSnapshot()?.code;
            if (hasTranslate) {
                arr.push(this.getForm(hasTranslate, isRequired))
            } else {
                arr.push(this.getForm({languageId: l.id}, isRequired))
            }
        });

        return arr;
    }


}
