import {Injectable} from "@angular/core";
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "@ypa/constants/environments";
import {ReportTabRepository} from "state-management/shared/report-tab";
import {BaseCommonReportTabInterface, CommonReportTabInterface, ReportTabParams} from "@ypa/types/report-tab";
import { EntityListInterface } from "@ypa/types/base-entity";

@Injectable({providedIn: 'root'})
export class ReportTabService extends AbstractEntityRepositoryServices<CommonReportTabInterface, BaseCommonReportTabInterface, ReportTabParams> {

    constructor(
        private http: HttpClient,
        private reportTabRepository: ReportTabRepository
    ) {
        super(reportTabRepository)
    }

    protected getAllReq(): Observable<CommonReportTabInterface[]> {
        return this.http.get<CommonReportTabInterface[]>(`${environment.apiUrl}/coach/report-tab`);
    }

    protected createReq(form: BaseCommonReportTabInterface | Partial<CommonReportTabInterface>): Observable<CommonReportTabInterface> {
        return this.http.post<CommonReportTabInterface>(`${environment.apiUrl}/coach/report-tab`, form);
    }

    protected updateReq(id: number, form: Partial<CommonReportTabInterface>): Observable<CommonReportTabInterface> {
        return this.http.patch<CommonReportTabInterface>(`${environment.apiUrl}/coach/report-tab/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/coach/report-tab/${id}`);
    }

    protected getByIdReq(id: number): Observable<CommonReportTabInterface> {
        return this.http.get<CommonReportTabInterface>(`${environment.apiUrl}/coach/report-tab/${id}`);
    }

    protected getByReq(params: ReportTabParams): Observable<CommonReportTabInterface[]> {
        return this.http.get<CommonReportTabInterface[]>(`${environment.apiUrl}/coach/report-tab`, {params: params as {}});
    }

    protected updateListReq(list: EntityListInterface<CommonReportTabInterface>): Observable<EntityListInterface<CommonReportTabInterface>> {
        throw new Error("Method not implemented.");
    }
}
