import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '@ypa/constants/environments';
import {BaseUserRoleInterface, UserRoleInterface, UserRoleParams} from '@ypa/types/user-role';
import {AbstractEntityClubRelationRepositoryService} from "@ypa/data-access/abstract-repository-services";
import {UserRoleRepository} from "@ypa/state-management/shared/user-role";
import { EntityListInterface } from '@ypa/types/base-entity';

@Injectable({providedIn: 'root'})
export class UserRoleService extends AbstractEntityClubRelationRepositoryService<UserRoleInterface, BaseUserRoleInterface, UserRoleParams> {
    constructor(
        private http: HttpClient,
        private userRoleRepository: UserRoleRepository
    ) {
        super(userRoleRepository);
    }

    protected getByClubIdReq(clubId: number): Observable<UserRoleInterface[]> {
        const params = {
            clubId
        }
        return this.http.get<UserRoleInterface[]>(`${environment.apiUrl}/user-role`, {params})
    }

    protected getAllReq(): Observable<UserRoleInterface[]> {
        return this.http.get<UserRoleInterface[]>(`${environment.apiUrl}/user-role`)
    }

    protected createReq(form: BaseUserRoleInterface | Partial<UserRoleInterface>): Observable<UserRoleInterface> {
        return throwError(() => new Error('Create user-role api is not implemented'));
    }

    protected updateReq(id: number, form: Partial<UserRoleInterface>): Observable<UserRoleInterface> {
        return throwError(() => new Error('Update user-role api is not implemented'));
    }

    protected removeReq(id: number): Observable<any> {
        return throwError(() => new Error('Remove user-role api is not implemented'));
    }

    protected getByIdReq(id: number): Observable<UserRoleInterface> {
        return throwError(() => new Error('Get user-role by id api is not implemented'));
    }

    protected getByReq(params: UserRoleParams): Observable<UserRoleInterface[]> {
        return this.http.get<UserRoleInterface[]>(`${environment.apiUrl}/user-role`, {params: params as {}})
    }

    protected updateListReq(list: EntityListInterface<UserRoleInterface>): Observable<EntityListInterface<UserRoleInterface>> {
        throw new Error('Method not implemented.');
    }
}
