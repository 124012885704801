import {inject, Injectable} from '@angular/core';
import {AbstractFormService} from "@ypa/data-access/abstract-form";
import {ControlsOf, FormArray, FormGroup} from "@ngneat/reactive-forms";
import {Validators} from "@angular/forms";
import {UiCoachDashboardMetricFormInterface} from "@ypa/types/coach-dashboard-metric";
import {AggregateTypeEnum} from "@ypa/enums/aggregate-type";
import {LanguageFormService} from "@ypa/data-access/language";


@Injectable()
export class CoachDashboardMetricFormService extends AbstractFormService<UiCoachDashboardMetricFormInterface> {

    private languageFormService = inject(LanguageFormService);

    private dayCalculationTypeEnum = AggregateTypeEnum;

    getForm(params: Partial<UiCoachDashboardMetricFormInterface>): FormGroup<ControlsOf<Partial<UiCoachDashboardMetricFormInterface>>> {

        let form = {};

        if (params.id !== null && typeof params.id !== "undefined") {
            form = {
                id: this.fb.control(params.id, [Validators.required]),
            }
        }

        if (params.metricName !== null && typeof params.metricName !== "undefined") {
            form = {
                ...form,
                metricName: this.fb.group(this.languageFormService.getTranslationControls(params.metricName)),
            }
        }

        return this.fb.group({
            ...form,
            coachDashboardId: this.fb.control(),
            metricId: this.fb.control(params.metricId, [Validators.required]),
            isMain: this.fb.control(params.isMain ? params.isMain : false, Validators.required),
            forDays: this.fb.control(params.forDays ? params.forDays : 1, [Validators.required, Validators.min(1), Validators.max(365)]),
            dayCalculationType: this.fb.control(params.dayCalculationType ? params.dayCalculationType : this.dayCalculationTypeEnum.sum, Validators.required),
            deleted: this.fb.control(false)
        });
    }

    getCoachDashboardMetricArray(params: UiCoachDashboardMetricFormInterface[]): FormArray<Partial<UiCoachDashboardMetricFormInterface>> {
        return this.fb.array<Partial<UiCoachDashboardMetricFormInterface>>(params.map(param => this.getForm(param)));
    }
}
