import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, Observable, tap, throwError} from 'rxjs';
import {environment} from '@ypa/constants/environments';
import {CollectorRepository} from "@ypa/state-management/shared/collector";
import {AbstractEntityWithActiveIdRepositoryService} from "@ypa/data-access/abstract-repository-services";
import {BaseCommonCollectorInterface, CommonCollectorInterface, CommonCollectorParams} from "@ypa/types/collector";
import {EntityListInterface} from "@ypa/types/base-entity";

@Injectable({providedIn: "root"})
export class CollectorService extends AbstractEntityWithActiveIdRepositoryService<CommonCollectorInterface, BaseCommonCollectorInterface, CommonCollectorParams> {
    constructor(
        private http: HttpClient,
        private collectorRepository: CollectorRepository
    ) {
        super(collectorRepository);
        this.entities$ = collectorRepository.entities$.pipe(
            map(x => this.sortByPosition(x))
        )
    }

    protected getAllReq(): Observable<CommonCollectorInterface[]> {
        return throwError(() => new Error('Get all collectors api is not implemented'))
    }

    protected createReq(form: BaseCommonCollectorInterface | Partial<CommonCollectorInterface>): Observable<CommonCollectorInterface> {
        return this.http.post<CommonCollectorInterface>(`${environment.apiUrl}/collector`, form);
    }

    protected updateReq(id: number, form: Partial<CommonCollectorInterface>): Observable<CommonCollectorInterface> {
        return this.http.patch<CommonCollectorInterface>(`${environment.apiUrl}/collector/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/collector/${id}`);
    }

    protected getByIdReq(id: number): Observable<CommonCollectorInterface> {
        return this.http.get<CommonCollectorInterface>(`${environment.apiUrl}/collector/${id}`);
    }

    protected getByReq(params: CommonCollectorParams): Observable<CommonCollectorInterface[]> {
        return this.http.get<CommonCollectorInterface[]>(`${environment.apiUrl}/collector`, {params: params as {}});
    }

    protected updateListReq(list: EntityListInterface<CommonCollectorInterface>): Observable<EntityListInterface<CommonCollectorInterface>> {
        throw new Error("Method not implemented.");
    }

    private sortByPosition(collectors: CommonCollectorInterface[]) {
        return collectors.sort((a, b) => {
            if (a.position > b.position) {
                return 1;
            } else if (a.position < b.position) {
                return -1;
            } else {
                return 0;
            }
        });
    }
}

