import {map, Observable, of, switchMap, tap} from "rxjs";
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {AbstractEntityWithActiveIdRepository} from "@ypa/state-management/shared/abstract";
import {AbstractRequestCheckerService} from "./abstract-request-checker.service";
import {BaseEntityInterface, BaseEntityParams, EntityListInterface} from "@ypa/types/base-entity";
import {GetByTypeEnum} from "@ypa/enums/get-by-type";

export abstract class AbstractEntityRepositoryServices<T extends BaseEntityInterface, TBase, TParams extends BaseEntityParams> extends AbstractRequestCheckerService {

    entities$: Observable<T[]>;

    protected constructor(
        private repository: AbstractEntityRepository<T, TParams> | AbstractEntityWithActiveIdRepository<T, TParams>
    ) {
        super();
        this.entities$ = repository.entities$;
    }

    clear() {
        this.repository.clear();
        this.clearRequests();
    }

    sync(force = false) {
        if (!force && this.repository.isSynchronised) {
            return this.entities$;
        }

        return this.getAllReq().pipe(
            tap(data => {
                this.repository.upsert(data);

                if (data.length) {
                    this.repository.isSynchronised = true;
                }
            }),
            switchMap(() => this.entities$)
        );
    }

    create(form: TBase | Partial<T>): Observable<T> {
        return this.createReq(form).pipe(
            tap(data => this.repository.upsert(data))
        );
    }

    update(id: number, form: Partial<T>): Observable<T> {
        return this.updateReq(id, form).pipe(
            tap(data => this.repository.upsert(data))
        );
    }

    remove(id: number): Observable<any> {
        return this.removeReq(id).pipe(
            tap(() => this.repository.remove(id))
        )
    }

    removeFromRepository(id: number | number[]) {
        this.repository.remove(id);
    }

    getById(id: number, mode = GetByTypeEnum.regular): Observable<T> {
        let externalCall = true;
        return this.repository.getById(id).pipe(
            switchMap(entity => {

                if ((entity || mode === GetByTypeEnum.repository || !externalCall) && mode !== GetByTypeEnum.force) {
                    externalCall = false;
                    return of(entity)
                }

                externalCall = false;

                return this.getByIdReq(id).pipe(
                    tap(data => this.repository.upsert(data)),
                    switchMap(() => this.repository.getById(id))
                );
            }),
        )
    }

    getBy(params: TParams, mode = GetByTypeEnum.regular): Observable<T[]> {
        const key = `getBy${JSON.stringify(params)}`;
        if ((this.isRepositoryDataValid(key, mode === GetByTypeEnum.repositoryIfRequested) || this.repository.isSynchronised || mode === GetByTypeEnum.repository) && mode !== GetByTypeEnum.force) {
            return this.repository.getBy(params);
        }

        return this.getByReq(params).pipe(
            tap(data => this.repository.upsert(data)),
            tap(() => this.addTimeCheck(key)),
            switchMap(() => {
                return this.repository.getBy(params)
            })
        );
    }

    updateList(list: T[]): Observable<T[]> {
        return this.updateListReq({list}).pipe(
            tap(data => this.repository.upsert(data.list)),
            map(data => data.list)
        );
    }

    getSnapshot(): T[] {
        return this.repository.getSnapshot();
    }

    updateRepositoryForEntity(data: T) {
        this.repository.upsert(data);
    }

    protected abstract getAllReq(): Observable<T[]>;

    protected abstract createReq(form: TBase | Partial<T>): Observable<T>;

    protected abstract updateReq(id: number, form: Partial<T>): Observable<T>;

    protected abstract removeReq(id: number): Observable<any>;

    protected abstract getByIdReq(id: number): Observable<T>;

    protected abstract getByReq(params: TParams): Observable<T[]>;

    protected abstract updateListReq(list: EntityListInterface<T>): Observable<EntityListInterface<T>>;

}
