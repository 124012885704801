import {ChangeDetectorRef, Directive, inject, Input, OnDestroy, OnInit} from "@angular/core";
import {FormControl} from "@angular/forms";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {TranslationsKeyHelper} from "../helpers/translations-key.helper";

@Directive()
export abstract class AbstractErrorsPanelComponent implements OnInit, OnDestroy {
    @Input() control: FormControl;

    protected subscription = new Subscription();
    protected changeDetectorRef = inject(ChangeDetectorRef);
    protected translateService = inject(TranslateService);

    ngOnInit(): void {
        this.detectControlChanges();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    getErrors() {
        return this.control.errors ? this.extractErrors(this.control) : [];
    }

    extractErrors(control: FormControl): string[] {
        const controlErrors: { [K: string]: any } | null = control.errors;
        const errorObjects = controlErrors ? Object.entries(controlErrors) : [];
        return errorObjects.map(errorObject => {
            if (typeof errorObject[1] === 'string') {
                return errorObject[1]
            }

            return this.translateService.instant(TranslationsKeyHelper.getTranslationKey(errorObject[0]));
        })
    }

    private detectControlChanges() {
        if (this.control) {
            const subs = this.control.statusChanges.subscribe(() => {
                this.changeDetectorRef.detectChanges();
            });

            this.subscription.add(subs);
        }
    }
}
