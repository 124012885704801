import {ChangeDetectionStrategy, Component} from '@angular/core';
import { CommonModule } from '@angular/common';
import {AbstractErrorsPanelComponent} from "../../abstract/abstract-errors-panel.component";

@Component({
    // eslint-disable-next-line @angular-eslint/components-selector
    selector: 'errors-panel',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './errors-panel.component.html',
    styleUrls: ['./errors-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorsPanelComponent extends AbstractErrorsPanelComponent{
}
