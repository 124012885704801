import { Injectable } from '@angular/core';
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";
import {CoachDashboardMetricInterface} from "@ypa/types/coach-dashboard-metric";
import {
    CoachDashboardMetricParams
} from "@ypa/types/coach-dashboard-metric";

const name = 'COACH_DASHBOARD_METRIC';

@Injectable({
  providedIn: 'root'
})
export class CoachDashboardMetricRepository extends AbstractEntityRepository<CoachDashboardMetricInterface, CoachDashboardMetricParams> {
    protected readonly store = createStore({name}, withEntities<CoachDashboardMetricInterface>());
}
