import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {OvdalError} from "@ypa/ovdal/ovdal-errors";

export abstract class AbstractErrorInterceptor implements HttpInterceptor {

    protected abstract showError(error: OvdalError): void;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                this.handleError(req, error);
                return throwError(() => error);
            })
        );
    }

    private handleError(req: HttpRequest<any>, error: HttpErrorResponse) {
        if (
            error.status === 401 ||
            ((req.method === 'POST' || req.method === 'PUT' || req.method === 'PATCH') && error.error.validationErrors) ||
            req.url.includes('/app-version/check-restrictions')
        ) {
            return;
        }

        this.showError(error.error as OvdalError)
    }
}
