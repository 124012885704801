import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@ypa/constants/environments';
import {map, Observable, throwError} from 'rxjs';
import {BaseClubInterface, ClubInterface, ClubParams} from '@ypa/types/club';
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {ClubRepository} from "@ypa/state-management/shared/club";
import {GetByTypeEnum} from "@ypa/enums/get-by-type";
import {EntityListInterface} from "@ypa/types/base-entity";

@Injectable({providedIn: "root"})
export class ClubService extends AbstractEntityRepositoryServices<ClubInterface, BaseClubInterface, ClubParams> {
    constructor(
        private http: HttpClient,
        private clubRepository: ClubRepository
    ) {
        super(clubRepository);
        this.entities$ = clubRepository.entities$.pipe(
            map((clubs) => this.sortNested(clubs)),
            map((clubs) => {
                    return clubs.map((club) => {
                        return {...club, deep: this.calculateDeep(club, clubs)};
                    })
                }
            )
        )
    }

    getBy(params: ClubParams, mode = GetByTypeEnum.regular): Observable<ClubInterface[]> {
        // entity club has not property club id, so it's not possible to get it from repository by clubId,
        // that is why force should be true if club id param is passed
        if (typeof params.clubId !== "undefined" && params.clubId !== null) {
            mode = GetByTypeEnum.force;
        }
        return super.getBy(params, mode);
    }

    protected getAllReq(): Observable<ClubInterface[]> {
        return this.http.get<ClubInterface[]>(`${environment.apiUrl}${environment.endpointAdditionalPrefix}/club`);
    }

    protected createReq(form: BaseClubInterface | Partial<ClubInterface>): Observable<ClubInterface> {
        return this.http.post<ClubInterface>(`${environment.apiUrl}/club`, form);
    }

    protected updateReq(id: number, form: Partial<ClubInterface>): Observable<ClubInterface> {
        return this.http.patch<ClubInterface>(`${environment.apiUrl}/club/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return throwError(() => new Error('Remove club method is not implemented'));
    }

    protected getByIdReq(id: number): Observable<ClubInterface> {
        return this.http.get<ClubInterface>(`${environment.apiUrl}/club/${id}`)
    }

    protected getByReq(params: ClubParams): Observable<ClubInterface[]> {
        return this.http.get<ClubInterface[]>(`${environment.apiUrl}/club`, {params: params as {}});
    }

    protected updateListReq(list: EntityListInterface<ClubInterface>): Observable<EntityListInterface<ClubInterface>> {
        throw new Error("Method not implemented.");
    }


    private sortNested(clubs: ClubInterface[]) {
        return clubs.reduce((acc: ClubInterface[], curr: ClubInterface) => {
            const parentIdx = acc.findIndex(
                (club) => club.id === curr.parentId
            );
            if (parentIdx !== -1) {
                acc.splice(parentIdx + 1, 0, curr);
            } else {
                acc.push(curr);
            }
            return acc;
        }, []);
    }

    private calculateDeep(
        club: ClubInterface,
        clubs: ClubInterface[],
        deep = 0
    ): number {
        const parent = clubs.find((c) => c.id === club.parentId);

        if (parent) {
            return this.calculateDeep(parent, clubs, deep + 1);
        }

        return deep;
    }
}
