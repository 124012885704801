import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {authGuardFn} from "./guards/auth.guard";

const routes: Routes = [
    {
        path: 'auth',
        loadComponent: () => import('./pages/auth/login/login.component').then(c => c.LoginComponent),
    },
    {
        path: '',
        loadChildren: () => import('./pages/dashboard/routes').then(r => r.ROUTES),
        canActivate: [authGuardFn]
    },
    {
        path: 'forgot',
        loadChildren: () => import('./pages/auth/forgot-password/routes').then((r) => r.ROUTES)
    },
    {
        path: 'sign-up',
        loadComponent: () => import('./pages/onboarding/components/signup-by-phone/signup-by-phone.component').then((c) => c.SignupByPhoneComponent),
    },
    {
        path: 'sign-up/create-profile',
        loadComponent: () => import('./pages/onboarding/components/create-profile-by-phone/create-profile-by-phone.component').then((c) => c.CreateProfileByPhoneComponent),
    },
    {
        path: 'onboarding',
        loadChildren: () => import('./pages/onboarding/routes').then((r) => r.ROUTES)
    },
    {
        path: 'contact',
        loadChildren: () => import('./pages/contact/routes').then((r) => r.ROUTES)
    },
    {
        path: 'delete-user',
        loadComponent: () => import('./pages/external-user-delete/external-user-delete.component').then(c => c.ExternalUserDeleteComponent)
    },
    {
        path: '**',
        loadComponent: () => import('./pages/page-not-found/page-not-found.component').then((c) => c.PageNotFoundComponent)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
