import { Injectable } from '@angular/core';
import { createStore, withProps } from '@ngneat/elf';
import { UserInterface } from '@ypa/types/user';
import {AbstractPropertyRepository} from "@ypa/state-management/shared/abstract";

const name = 'AUTH';

@Injectable({providedIn: "root"})
export class UserAuthRepository extends AbstractPropertyRepository<UserInterface> {
    protected readonly store = createStore({name}, withProps<UserInterface | null>(null));
}
