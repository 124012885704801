import {
    BaseEntityClubEnableAllInterface,
    BaseEntityClubRelationInterface,
    BaseEntityInterface,
    BaseEntityParams, EntityWithClubRelationParams
} from "@ypa/types/base-entity";
import {AbstractEntityClubRelationRepositoryService} from "@ypa/data-access/abstract-repository-services";
import {Observable, of, switchMap, tap} from "rxjs";
import {AbstractEntityClubRelationRepository} from "@ypa/state-management/shared/abstract";

export abstract class AbstractEntityClubRelationWithEnableAllRepositoryService<T extends BaseEntityInterface, TBase extends BaseEntityClubRelationInterface, TParams extends EntityWithClubRelationParams, TEnable extends TBase & BaseEntityClubEnableAllInterface> extends AbstractEntityClubRelationRepositoryService<T, TBase, TParams> {

    protected constructor(
        private entityClubRelationRepository: AbstractEntityClubRelationRepository<T, TParams>
    ) {
        super(entityClubRelationRepository);
    }

    enableForAll(form: TEnable): Observable<T[]> {
        const data = form as any;
        if (!data.clubId) {
            delete data.clubId
        }

        return this.enableForAllReq(data as TEnable).pipe(
            tap(data => this.entityClubRelationRepository.upsert(data))
        )
    }

    protected abstract enableForAllReq(form: TEnable): Observable<T[]>
}
