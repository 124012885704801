import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {
    AbstractEntityClubRelationRepositoryService,
} from "@ypa/data-access/abstract-repository-services";
import {Observable, of, switchMap} from "rxjs";
import {selectManyByPredicate} from "@ngneat/elf-entities";
import {BaseEntityInterface, BaseEntityParams, EntityWithClubRelationParams} from "@ypa/types/base-entity";
import {GetByTypeEnum} from "@ypa/enums/get-by-type";

export abstract class AbstractEntityWithClubRelationRepository<T extends BaseEntityInterface, TClubRelation extends BaseEntityInterface, BaseTClubRelation, TParams extends EntityWithClubRelationParams, TClubRelationParams extends BaseEntityParams> extends AbstractEntityRepository<T, TParams> {
    protected constructor(private clubRelationRepositoryService: AbstractEntityClubRelationRepositoryService<TClubRelation, BaseTClubRelation, TClubRelationParams>) {
        super();
    }

    protected abstract isMatchedEntityWithClubRelation(clubRelations: TClubRelation, obj:T): boolean;

    override getBy(params: TParams, mode = GetByTypeEnum.regular): Observable<T[]> {
        let start = of<TClubRelation[]>([]);
        let isWithClubId = false;
        if ((params as EntityWithClubRelationParams).clubId) {
            start = this.clubRelationRepositoryService.getByClubId((params as EntityWithClubRelationParams).clubId as number, mode);
            isWithClubId = true;
        }
        delete (params as EntityWithClubRelationParams).clubId;

        return start.pipe(
            switchMap(clubRelations => this.store.pipe(selectManyByPredicate((obj: T) => {
                if (isWithClubId) {
                    const clubIdMatched = clubRelations.some(x => this.isMatchedEntityWithClubRelation(x, obj));
                    return clubIdMatched && this.isMatchParams(params, obj);
                }

                return this.isMatchParams(params, obj);
            })))
        ) as Observable<T[]>;
    }
}
