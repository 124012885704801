import {Injectable} from '@angular/core';
import {CurrentRoleRepository} from "@ypa/state-management/shared/current-role";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CurrentRoleService {

    constructor(private currentRoleRepository: CurrentRoleRepository) {
    }

    set(clubId: number) {
        this.currentRoleRepository.update(clubId)
    }

    get(): Observable<number> {
        return this.currentRoleRepository.data$;
    }

    getSnapShot(): number {
        return this.currentRoleRepository.getSnapshot()
    }

    isEmpty(): boolean {
        return this.currentRoleRepository.isEmpty;
    }

    clear() {
        this.currentRoleRepository.clear()
    }
}
