import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {Observable, switchMap, tap} from "rxjs";
import {AbstractEntityClubRelationRepository} from "@ypa/state-management/shared/abstract";
import {BaseEntityInterface, BaseEntityParams} from "@ypa/types/base-entity";
import {GetByTypeEnum} from "@ypa/enums/get-by-type";

export abstract class AbstractEntityClubRelationRepositoryService<T extends BaseEntityInterface, TBase, TParams extends BaseEntityParams> extends AbstractEntityRepositoryServices<T, TBase, TParams> {

    protected constructor(
        private clubRelationRepository: AbstractEntityClubRelationRepository<T, TParams>
    ) {
        super(clubRelationRepository);
    }

    getByClubId(id: number, mode = GetByTypeEnum.regular): Observable<T[]> {
        const key = `getBy${JSON.stringify({clubId: id})}`;
        if ((this.isRepositoryDataValid(key, mode === GetByTypeEnum.repositoryIfRequested) || this.clubRelationRepository.isSynchronised || mode === GetByTypeEnum.repository) && mode !== GetByTypeEnum.force) {
            return this.clubRelationRepository.getByClubId(id);
        }

        return this.getByClubIdReq(id).pipe(
            tap(data => this.clubRelationRepository.upsert(data)),
            tap(() => this.addTimeCheck(key)),
            switchMap(() => this.clubRelationRepository.getByClubId(id))
        );
    }

    protected abstract getByClubIdReq(clubId: number): Observable<T[]>;
}
