import {Injectable} from "@angular/core";
import {AbstractReportTabWithRangeFormService} from "@ypa/data-access/abstract-form";
import {ControlsOf, FormGroup} from "@ngneat/reactive-forms";
import {Validators} from "@angular/forms";
import {CommonReportTabInterface} from "@ypa/types/report-tab";

@Injectable({providedIn: 'root'})
export class ReportTabFormService extends AbstractReportTabWithRangeFormService<CommonReportTabInterface> {
    getForm(params: Partial<CommonReportTabInterface>): FormGroup<ControlsOf<Partial<CommonReportTabInterface>>> {
        const controls = this.getIdControlIfExist(params);

        return this.fb.group({
            ...controls,
            ...{
                name: this.fb.control(params.name, [Validators.required]),
                type: this.fb.control(params.type, [Validators.required]),
                userId: this.fb.control(params.userId != null ? params.userId : null),
                clubId: this.fb.control(params.clubId != null ? params.clubId : null)
            }
        }) as FormGroup<Partial<ControlsOf<CommonReportTabInterface>>>;
    }
}
