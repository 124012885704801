import {EventEmitter, Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class SystemEventsService {

    /**
     * system logout event
     */
    private logoutSubject = new EventEmitter<void>();
    logout$ = this.logoutSubject.asObservable();

    fireLogout() {
        this.logoutSubject.next();
    }

    /**
     * system login event
     */
    private loginSubject = new EventEmitter<void>();
    login$ = this.loginSubject.asObservable();

    fireLogin() {
        this.loginSubject.next();
    }
}
