import {Injectable} from '@angular/core';
import {createStore} from '@ngneat/elf';
import {selectManyByPredicate, withEntities} from '@ngneat/elf-entities';
import {UserInterface, UserParams} from '@ypa/types/user';
import {
    AbstractEntityWithClubRelationRepository
} from "@ypa/state-management/shared/abstract";
import {BaseUserRoleInterface, UserRoleInterface, UserRoleParams} from "@ypa/types/user-role";
import {UserRoleService} from "@ypa/data-access/user-role";
import {BaseEntityParams, EntityWithClubRelationParams} from "@ypa/types/base-entity";
import {GetByTypeEnum} from "@ypa/enums/get-by-type";
import {map, Observable, of, switchMap} from "rxjs";
import {RoleService} from "@ypa/data-access/role";
import {RoleInterface} from "@ypa/types/role";
import {RoleTypeEnum} from "@ypa/enums/role-type";

const name = 'USERS';

@Injectable({providedIn: "root"})
export class UserRepository extends AbstractEntityWithClubRelationRepository<UserInterface, UserRoleInterface, BaseUserRoleInterface, UserParams, UserRoleParams> {
    protected readonly store = createStore({name}, withEntities<UserInterface>());

    constructor(
        private userRoleService: UserRoleService,
        private roleService: RoleService
    ) {
        super(userRoleService);
    }

    protected isMatchedEntityWithClubRelation(clubRelations: UserRoleInterface, obj: UserInterface): boolean {
        return clubRelations.userId === obj.id;
    }

    private isMatchedByRoleType(clubRelation: UserRoleInterface, user: UserInterface, roles: RoleInterface[], roleType: RoleTypeEnum): boolean {
        const role = roles.find(r => r.id === clubRelation.roleId);
        if (role == null) {
            return false;
        }

        return user.id === clubRelation.userId && role.type === roleType;
    }


    override getBy(params: UserParams, mode = GetByTypeEnum.regular): Observable<UserInterface[]> {
        let start = of<{ userRoles: UserRoleInterface[], roles?: RoleInterface[] }>({userRoles: [], roles: []});
        // const start: Observable<UserRoleInterface[] | RoleInterface[]>[] = [];
        let isWithClubId = false;
        let isWithRoleId = false;
        let isWithRoleType = false;
        let roleType: RoleTypeEnum | null = null;

        const userRoleParams: UserRoleParams = {};
        if (params.clubId) {
            userRoleParams.clubId = params.clubId;
            isWithClubId = true;
        }
        if (params.roleId) {
            userRoleParams.roleId = params.roleId;
            isWithRoleId = true;
        }

        if (params.roleType) {
            roleType = params.roleType;
            isWithRoleType = true;
        }

        if ((params.clubId || params.roleId) && !params.roleType) {
            start = this.userRoleService.getBy(userRoleParams, mode).pipe(
                map(userRoles => ({userRoles}))
            );
        } else if (params.roleType) {
            start = this.userRoleService.getBy(userRoleParams, mode).pipe(
                switchMap(userRoles => {
                    return this.roleService.getBy({type: roleType}).pipe(
                        map(roles => {
                            return {userRoles, roles};
                        })
                    )
                })
            );
        }

        delete params.clubId;
        delete params.roleId;
        delete params.roleType;

        return start.pipe(
            switchMap(data => {
                return this.store.pipe(selectManyByPredicate((user: UserInterface) => {
                    let roleIdMatched = true;
                    let clubIdMatched = true;
                    let roleTypeMatched = true;

                    if (isWithRoleId) {
                        roleIdMatched = data.userRoles.some(x => x.userId === user.id && x.roleId === userRoleParams.roleId);
                    }

                    if (isWithClubId) {
                        clubIdMatched = data.userRoles.some(x => this.isMatchedEntityWithClubRelation(x, user));
                    }

                    if (isWithRoleType) {
                        roleTypeMatched = data.userRoles.some(x => this.isMatchedByRoleType(x, user, data.roles, roleType))
                    }

                    return clubIdMatched && roleIdMatched && roleTypeMatched && this.isMatchParams(params, user);
                }))
            })
        ) as Observable<UserInterface[]>;
    }
}
