import {Injectable} from "@angular/core";
import {
    AbstractEntityClubRelationWithEnableAllRepositoryService
} from "@ypa/data-access/abstract-repository-services";
import {HttpClient} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {environment} from "@ypa/constants/environments";
import {
    BaseUserDashboardMetricClubInterface,
    UserDashboardMetricClubEnableInterface,
    UserDashboardMetricClubInterface,
    UserDashboardMetricClubParams
} from "@ypa/types/user-dashboard-metric-club";
import {UserDashboardMetricClubRepository} from "@ypa/state-management/shared/user-dashboard-metric-club";
import {EntityListInterface} from "@ypa/types/base-entity";

@Injectable({providedIn: 'root'})
export class UserDashboardMetricClubService extends AbstractEntityClubRelationWithEnableAllRepositoryService<UserDashboardMetricClubInterface, BaseUserDashboardMetricClubInterface, UserDashboardMetricClubParams, UserDashboardMetricClubEnableInterface> {
    constructor(
        private http: HttpClient,
        private userDashboardMetricClubRepository: UserDashboardMetricClubRepository
    ) {
        super(userDashboardMetricClubRepository);
    }

    protected enableForAllReq(form: UserDashboardMetricClubEnableInterface): Observable<UserDashboardMetricClubInterface[]> {
        return this.http.post<UserDashboardMetricClubInterface[]>(`${environment.apiUrl}/user-dashboard-metric-club/enabled`, form);
    }

    protected getByClubIdReq(clubId: number): Observable<UserDashboardMetricClubInterface[]> {
        const params = {
            clubId
        };
        return this.http.get<UserDashboardMetricClubInterface[]>(`${environment.apiUrl}/user-dashboard-metric-club`, {params});
    }

    protected getAllReq(): Observable<UserDashboardMetricClubInterface[]> {
        return this.http.get<UserDashboardMetricClubInterface[]>(`${environment.apiUrl}/user-dashboard-metric-club`);
    }

    protected createReq(form: BaseUserDashboardMetricClubInterface | Partial<UserDashboardMetricClubInterface>): Observable<UserDashboardMetricClubInterface> {
        return this.http.post<UserDashboardMetricClubInterface>(`${environment.apiUrl}/user-dashboard-metric-club`, form);
    }

    protected updateReq(id: number, form: Partial<UserDashboardMetricClubInterface>): Observable<UserDashboardMetricClubInterface> {
        return this.http.put<UserDashboardMetricClubInterface>(`${environment.apiUrl}/user-dashboard-metric-club/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/user-dashboard-metric-club/${id}`);
    }

    protected getByIdReq(id: number): Observable<UserDashboardMetricClubInterface> {
        return throwError(() => new Error('get user dashboard metric club by id api is not implemented'));
    }

    protected getByReq(params: UserDashboardMetricClubParams): Observable<UserDashboardMetricClubInterface[]> {
        return this.http.get<UserDashboardMetricClubInterface[]>(`${environment.apiUrl}/user-dashboard-metric-club`, {params: params as {}});
    }

    protected updateListReq(list: EntityListInterface<UserDashboardMetricClubInterface>): Observable<EntityListInterface<UserDashboardMetricClubInterface>> {
        throw new Error('Method not implemented.');
    }
}
