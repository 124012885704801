import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';
import {MetricReportInterface, MetricReportParams} from '@ypa/types/metric-report';
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";

const name = 'METRIC_REPORTS';

@Injectable({providedIn: "root"})
export class MetricReportRepository extends AbstractEntityRepository<MetricReportInterface, MetricReportParams>{
    protected readonly store = createStore(
        {name},
        withEntities<MetricReportInterface>()
    );
}
