import {Injectable} from "@angular/core";
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {HttpClient} from "@angular/common/http";
import {Observable, tap} from "rxjs";
import {environment} from "@ypa/constants/environments";
import {BaseEntityTagInterface, EntityTagInterface, EntityTagParams} from "@ypa/types/entity-tag";
import {TagRepository} from "@ypa/state-management/shared/tag";
import {EntityListInterface} from "@ypa/types/base-entity";
import {tags} from "@angular-devkit/core";

@Injectable({providedIn: "root"})
export class TagService extends AbstractEntityRepositoryServices<EntityTagInterface, BaseEntityTagInterface, EntityTagParams> {
    constructor(
        private http: HttpClient,
        private tagRepository: TagRepository
    ) {
        super(tagRepository);
    }

    getByUsersAllowedForms(): Observable<EntityTagInterface[]> {
        return this.getByUsersAllowedFormsReq().pipe(
            tap(tags => this.tagRepository.upsert(tags))
        )
    }

    private getByUsersAllowedFormsReq(): Observable<EntityTagInterface[]> {
        return this.http.get<EntityTagInterface[]>(`${environment.apiUrl}/entity-tag/by-form`);
    }

    protected getAllReq(): Observable<EntityTagInterface[]> {
        return this.http.get<EntityTagInterface[]>(`${environment.apiUrl}/entity-tag`);
    }

    protected createReq(form: BaseEntityTagInterface | Partial<EntityTagInterface>): Observable<EntityTagInterface> {
        return this.http.post<EntityTagInterface>(`${environment.apiUrl}/entity-tag`, form);
    }

    protected updateReq(id: number, form: Partial<EntityTagInterface>): Observable<EntityTagInterface> {
        return this.http.patch<EntityTagInterface>(`${environment.apiUrl}/entity-tag/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/entity-tag/${id}`);
    }

    protected getByIdReq(id: number): Observable<EntityTagInterface> {
        return this.http.get<EntityTagInterface>(`${environment.apiUrl}/entity-tag/${id}`);
    }

    protected getByReq(params: EntityTagParams): Observable<EntityTagInterface[]> {
        return this.http.get<EntityTagInterface[]>(`${environment.apiUrl}/entity-tag`, {params: params as {}});
    }

    protected updateListReq(list: EntityListInterface<EntityTagInterface>): Observable<EntityListInterface<EntityTagInterface>> {
        throw new Error('Method not implemented.');
    }
}
