import {Injectable} from "@angular/core";
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";
import {CommonReportTabInterface, ReportTabParams} from "@ypa/types/report-tab";

const name = 'REPORT_TAB';

@Injectable({providedIn: "root"})
export class ReportTabRepository extends AbstractEntityRepository<CommonReportTabInterface, ReportTabParams> {
    protected readonly store = createStore(
        {name},
        withEntities<CommonReportTabInterface>()
    );
}
