import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, tap, throwError} from 'rxjs';
import {environment} from '@ypa/constants/environments';
import {BaseFormClubInterface, FormClubEnableInterface, FormClubInterface, FormClubParams} from '@ypa/types/form-club';
import {
    AbstractEntityClubRelationWithEnableAllRepositoryService,
} from "@ypa/data-access/abstract-repository-services";
import {FormClubRepository} from "@ypa/state-management/shared/form-club";
import {EntityListInterface} from "@ypa/types/base-entity";

@Injectable({providedIn: "root"})
export class FormClubService extends AbstractEntityClubRelationWithEnableAllRepositoryService<FormClubInterface, BaseFormClubInterface, FormClubParams, FormClubEnableInterface> {
    constructor(
        private http: HttpClient,
        private formClubRepository: FormClubRepository
    ) {
        super(formClubRepository);
    }

    protected enableForAllReq(form: FormClubEnableInterface): Observable<FormClubInterface[]> {
        return this.http.post<FormClubInterface[]>(`${environment.apiUrl}/form-club/enabled`, form);
    }

    protected getByClubIdReq(clubId: number): Observable<FormClubInterface[]> {
        const params = {
            clubId
        }
        return this.http.get<FormClubInterface[]>(`${environment.apiUrl}/form-club`, {params});
    }

    protected getAllReq(): Observable<FormClubInterface[]> {
        return this.http.get<FormClubInterface[]>(`${environment.apiUrl}/form-club`);
    }

    protected createReq(form: BaseFormClubInterface | Partial<FormClubInterface>): Observable<FormClubInterface> {
        return this.http.post<FormClubInterface>(`${environment.apiUrl}/form-club`, form);
    }

    protected updateReq(id: number, form: Partial<FormClubInterface>): Observable<FormClubInterface> {
        return this.http.patch<FormClubInterface>(`${environment.apiUrl}/form-club/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/form-club/${id}`);
    }

    protected getByIdReq(id: number): Observable<FormClubInterface> {
        return throwError(() => new Error('Get form club by id not implemented'));
    }

    protected getByReq(params: FormClubParams): Observable<FormClubInterface[]> {
        return this.http.get<FormClubInterface[]>(`${environment.apiUrl}/form-club`, {params: params as {}});
    }

    protected updateListReq(list: EntityListInterface<FormClubInterface>): Observable<EntityListInterface<FormClubInterface>> {
        throw new Error('Method not implemented.');
    }
}
