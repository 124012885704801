export interface ForgotPassword {
    email?: string,
    phone?: string
}

export interface UpdatePassword {
    password: string;
    confirmPassword: string;
}

export interface UpdatePasswordEmail extends UpdatePassword {
    token: string;
}

export interface UpdatePasswordPhone extends UpdatePassword {
    code: string;
}
