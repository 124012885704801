import {Injectable} from "@angular/core";
import {
    AbstractEntityWithClubRelationRepositoryService
} from "@ypa/data-access/abstract-repository-services";
import {BaseUserInterface, UserInterface, UserParams} from "@ypa/types/user";
import {HttpClient} from "@angular/common/http";
import {UserRepository} from "@ypa/state-management/shared/user";
import {Observable, tap, throwError} from "rxjs";
import {environment} from "@ypa/constants/environments";
import {BaseUserRoleInterface, UserRoleInterface, UserRoleParams} from "@ypa/types/user-role";
import {EntityListInterface} from "@ypa/types/base-entity";

@Injectable({providedIn: "root"})
export class UserService extends AbstractEntityWithClubRelationRepositoryService<UserInterface, BaseUserInterface, UserRoleInterface, BaseUserRoleInterface, UserParams, UserRoleParams> {
    constructor(
        private http: HttpClient,
        private userRepository: UserRepository,
    ) {
        super(userRepository);
    }

    getWhoCanViewCurrentUsersMetrics(): Observable<UserInterface[]> {
        return this.getWhoCanViewCurrentUsersMetricsReq().pipe(
            tap(users => this.userRepository.upsert(users))
        )
    }

    uploadUserAvatar(file: Blob, userId: number): Observable<UserInterface> {
        const formParams = new FormData();
        formParams.append('file', file)
        return this.http.post<UserInterface>(`${environment.apiUrl}/admin/user/${userId}/avatar`, formParams, {
            // headers: new HttpHeaders({
            //     'Content-Type': 'multipart/form-data',
            // })
        }).pipe(
            tap(user => this.userRepository.upsert(user))
        );
    }

    private getWhoCanViewCurrentUsersMetricsReq(): Observable<UserInterface[]> {
        return this.http.get<UserInterface[]>(`${environment.apiUrl}/auth/can-view-my-metrics`);
    }

    protected getByReq(params: UserParams): Observable<UserInterface[]> {
        return this.http.get<UserInterface[]>(`${environment.apiUrl}/auth`, {params: params as {}});
    }

    protected getAllReq(): Observable<UserInterface[]> {
        return this.http.get<UserInterface[]>(`${environment.apiUrl}/auth`);
    }

    protected createReq(form: BaseUserInterface | Partial<UserInterface>): Observable<UserInterface> {
        return throwError(() => new Error('To create user you have to use signup api not user service'));
    }

    protected updateReq(id: number, form: Partial<UserInterface>): Observable<UserInterface> {
        return this.http.patch<UserInterface>(`${environment.apiUrl}/auth/${id}`, form);
    }
    
    public adminUpdatePassword(id: number, form: Partial<UserInterface>): Observable<UserInterface> {
        // just fake old password to pass validation
        form.oldPassword = form.password;

        return this.http.patch<UserInterface>(`${environment.apiUrl}/auth/admin-set-pass/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<UserInterface>(`${environment.apiUrl}/admin/auth/${id}`);
    }

    protected getByIdReq(id: number): Observable<UserInterface> {
        return this.http.get<UserInterface>(`${environment.apiUrl}/auth/${id}`);
    }

    public getByIds(ids: number[]): Observable<UserInterface[]> {
        return this.http.post<UserInterface[]>(`${environment.apiUrl}/auth/find-many`, {ids});
    }

    protected updateListReq(list: EntityListInterface<UserInterface>): Observable<EntityListInterface<UserInterface>> {
        throw new Error('Method not implemented.');
    }

}
