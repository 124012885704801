import {Injectable} from '@angular/core';
import {createStore, withProps} from "@ngneat/elf";
import {localStorageStrategy, persistState} from "@ngneat/elf-persist-state";
import {AbstractPropertyRepository} from "@ypa/state-management/shared/abstract";
import {environment} from "@ypa/constants/environments";

const name = 'CURRENT_CLUB';

@Injectable({providedIn: "root"})
export class CurrentClubRepository extends AbstractPropertyRepository<number> {
    protected store = createStore({name}, withProps<{data: number}>({ data: environment.currentClubDropdown.noClubId }));
    protected readonly persist = persistState(this.store, {
        key: name,
        storage: localStorageStrategy,
    });
}
