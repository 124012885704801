export enum WidgetTypeEnum {
    chart = "CHART", //
    table = "TABLE", //
    pieChart = "PIE_CHART", //
    gauge = "GAUGE", //
    metric = "METRIC", //
    radarChart = "RADAR_CHART",//
    scatterPlot = "SCATTER_PLOT",//
    colorCodes = "COLOR_CODES",//
    profilePicture = "PROFILE_PICTURE", //
    body = "BODY" //
}
