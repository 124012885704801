import {AbstractEntityClubRelationRepository} from "@ypa/state-management/shared/abstract";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";
import {Injectable} from "@angular/core";
import {UserDashboardMetricClubInterface, UserDashboardMetricClubParams} from "@ypa/types/user-dashboard-metric-club";

const name = 'USER_DASHBOARD_METRIC_CLUB';

@Injectable({providedIn: "root"})
export class UserDashboardMetricClubRepository extends AbstractEntityClubRelationRepository<UserDashboardMetricClubInterface, UserDashboardMetricClubParams> {
    protected readonly store = createStore({name}, withEntities<UserDashboardMetricClubInterface>());
}

