import {Injectable} from '@angular/core';
import {createStore} from '@ngneat/elf';
import {withEntities} from '@ngneat/elf-entities';
import {MetricClubInterface, MetricClubParams} from '@ypa/types/metric-club';
import {AbstractEntityClubRelationRepository} from "@ypa/state-management/shared/abstract";

const name = 'METRIC_CLUBS';

@Injectable({providedIn: "root"})
export class MetricClubRepository extends AbstractEntityClubRelationRepository<MetricClubInterface, MetricClubParams> {
    protected readonly store = createStore(
        {name},
        withEntities<MetricClubInterface>()
    );
}
