import { EnvironmentsInterface } from '@ypa/types/environments';
import {EnvironmentHelper} from "@ypa/helpers/environment";
import { LanguageCodeEnum } from "@ypa/enums/language-code";

export const environment: EnvironmentsInterface = {
    production: false,
    preProduction: false,
    apiUrl: 'https://api.ypa.ovdal.dk',
    contentApiUrl:'https://knowledgebank.ypa.ovdal.dk',
    roles: {
        coach: 1,
        athlete: 2,
    },
    defaultMetrics: {
        bodyMapMorningPain: 1,
        bodyMapMorningSoreness: 2,
        urine: 3,
        bodyMapTrainingPain: 4,
        bodyMapTrainingSoreness: 5,
    },
    calculationTypes: {
        group: {title: 'Group', value: 'group'},
        sum: {title: 'Sum', value: 'sum'},
        avg: {title: 'Avg', value: 'avg'},
        value: {title: 'Value', value: 'value'},
    },
    calculationPositions: {
        left: 0,
        right: 1
    },
    calculationOperators: {
        add: {title: 'Add', value: '+'},
        subtract: {title: 'Subtract', value: '-'},
        multiply: {title: 'Multiply', value: '*'},
        divide: {title: 'Divide', value: '/'},
        modulus: {title: 'Modulus', value: '%'},
    },
    bodyMetricIds: {
        morningPain: 1,
        morningSoreness: 2,
        trainingPain: 4,
        trainingSoreness: 5
    },
    currentClubDropdown: {
        noClubId: -1,
        noClubText: 'Admin'
    },
    currentRoleDropdown: {
        noRoleId: -1,
        noRoleText: "No role"
    },
    measureAccordance: EnvironmentHelper.getMeasureAccordance(),
    defaultLanguage: LanguageCodeEnum.Danish,
    allowedLanguages: [LanguageCodeEnum.Danish, LanguageCodeEnum.English],
};
