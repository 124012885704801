import {Injectable} from '@angular/core';
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";
import {LanguageInterface} from "@ypa/types/language";
import {LanguageParams} from "@ypa/types/language";

const name = 'LANGUAGE';

@Injectable({
    providedIn: 'root'
})
export class LanguageRepository extends AbstractEntityRepository<LanguageInterface, LanguageParams> {
    protected readonly store = createStore({name}, withEntities<LanguageInterface>());
}
