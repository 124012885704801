import {Injectable} from "@angular/core";
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";
import {EntityTagInterface, EntityTagParams} from "@ypa/types/entity-tag";

const name = 'TAGS';

@Injectable({providedIn: "root"})
export class TagRepository extends AbstractEntityRepository<EntityTagInterface, EntityTagParams> {
    protected readonly store = createStore(
        {name},
        withEntities<EntityTagInterface>()
    );
}
