import {Injectable} from '@angular/core';
import {createStore} from '@ngneat/elf';
import {withEntities} from '@ngneat/elf-entities';
import {UserRoleInterface, UserRoleParams} from '@ypa/types/user-role';
import {AbstractEntityClubRelationRepository} from "@ypa/state-management/shared/abstract";

const name = 'USER_ROLES';

@Injectable({providedIn: "root"})
export class UserRoleRepository extends AbstractEntityClubRelationRepository<UserRoleInterface, UserRoleParams> {
    protected readonly store = createStore({name}, withEntities<UserRoleInterface>());
}
