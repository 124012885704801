import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, tap} from 'rxjs';
import {environment} from '@ypa/constants/environments';
import {BaseFormInterface, FormInterface, FormParams} from '@ypa/types/form';
import {FormRepository} from "@ypa/state-management/shared/form";
import {
    AbstractEntityWithClubRelationRepositoryService
} from "@ypa/data-access/abstract-repository-services";
import {BaseFormClubInterface, FormClubInterface, FormClubParams} from "@ypa/types/form-club";
import { EntityListInterface } from '@ypa/types/base-entity';

@Injectable({providedIn: "root"})
export class FormService extends AbstractEntityWithClubRelationRepositoryService<FormInterface, BaseFormInterface, FormClubInterface, BaseFormClubInterface, FormParams, FormClubParams> {
    constructor(
        private http: HttpClient,
        private formRepository: FormRepository
    ) {
        super(formRepository);
    }


    // use this method only after synchronising the repository, or you certainly know that needed entity is already in the repository
    getByIdSynchronously(id: number): FormInterface {
        return this.formRepository.getSnapshot().find(x => x.id === id);
    }

    getUsersFormsInRange(from: Date, to: Date): Observable<FormInterface[]> {
        return this.getUsersFormsInRangeReq(from, to).pipe(
            tap(data => this.formRepository.upsert(data))
        )
    }

    cloneForm(id: number): Observable<FormInterface> {
        return this.cloneReq(id).pipe(
            tap(data => this.formRepository.upsert(data))
        )
    }

    private cloneReq(id: number): Observable<FormInterface> {
        const req = {formId: id};
        return this.http.post<FormInterface>(`${environment.apiUrl}/form/clone`, req);
    }

    private getUsersFormsInRangeReq(from: Date, to: Date): Observable<FormInterface[]> {
        const params = {
            from: from.toISOString(),
            to: to.toISOString()
        };
        return this.http.get<FormInterface[]>(`${environment.apiUrl}/form/by-task`, {params});
    }

    public getByReq(params: FormParams): Observable<FormInterface[]> {
        return this.http.get<FormInterface[]>(`${environment.apiUrl}${environment.endpointMainPrefix}/form`, {params: params as {}});
    }

    protected getAllReq(): Observable<FormInterface[]> {
        return this.http.get<FormInterface[]>(`${environment.apiUrl}${environment.endpointMainPrefix}/form`);
    }

    protected createReq(form: Partial<BaseFormInterface>): Observable<FormInterface> {
        return this.http.post<FormInterface>(`${environment.apiUrl}/form`, form);
    }

    protected updateReq(id: number, form: Partial<FormInterface>): Observable<FormInterface> {
        return this.http.patch<FormInterface>(`${environment.apiUrl}/form/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/form/${id}`);
    }

    protected getByIdReq(id: number): Observable<FormInterface> {
        return this.http.get<FormInterface>(`${environment.apiUrl}/form/${id}`);
    }

    protected updateListReq(list: EntityListInterface<FormInterface>): Observable<EntityListInterface<FormInterface>> {
        throw new Error('Method not implemented.');
    }
}
