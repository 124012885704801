import {Injectable} from "@angular/core";
import {
    AbstractEntityWithClubRelationRepositoryService
} from "@ypa/data-access/abstract-repository-services";
import {
    BaseUserDashboardMetricInterface,
    UserDashboardMetricInterface,
    UserDashboardMetricParams
} from "@ypa/types/user-dashboard-metric";
import {HttpClient} from "@angular/common/http";
import {UserDashboardMetricRepository} from "@ypa/state-management/shared/user-dashboard-metric";
import {Observable} from "rxjs";
import {environment} from "@ypa/constants/environments";
import {
    BaseUserDashboardMetricClubInterface,
    UserDashboardMetricClubInterface, UserDashboardMetricClubParams
} from "@ypa/types/user-dashboard-metric-club";
import { EntityListInterface } from "@ypa/types/base-entity";

@Injectable({providedIn: "root"})
export class UserDashboardMetricService extends AbstractEntityWithClubRelationRepositoryService<UserDashboardMetricInterface, BaseUserDashboardMetricInterface, UserDashboardMetricClubInterface, BaseUserDashboardMetricClubInterface, UserDashboardMetricParams, UserDashboardMetricClubParams> {
    constructor(
        private http: HttpClient,
        private userDashboardMetricRepository: UserDashboardMetricRepository
    ) {
        super(userDashboardMetricRepository);
    }

    protected getByReq(params: UserDashboardMetricParams): Observable<UserDashboardMetricInterface[]> {
        return this.http.get<UserDashboardMetricInterface[]>(`${environment.apiUrl}${environment.endpointMainPrefix}/user-dashboard-metric`, {params: params as {}});
    }

    protected getAllReq(): Observable<UserDashboardMetricInterface[]> {
        return this.http.get<UserDashboardMetricInterface[]>(`${environment.apiUrl}${environment.endpointMainPrefix}/user-dashboard-metric`);
    }

    protected createReq(form: BaseUserDashboardMetricInterface | Partial<UserDashboardMetricInterface>): Observable<UserDashboardMetricInterface> {
        return this.http.post<UserDashboardMetricInterface>(`${environment.apiUrl}/user-dashboard-metric`, form);
    }

    protected updateReq(id: number, form: Partial<UserDashboardMetricInterface>): Observable<UserDashboardMetricInterface> {
        return this.http.put<UserDashboardMetricInterface>(`${environment.apiUrl}/user-dashboard-metric/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/user-dashboard-metric/${id}`);
    }

    protected getByIdReq(id: number): Observable<UserDashboardMetricInterface> {
        return this.http.get<UserDashboardMetricInterface>(`${environment.apiUrl}/user-dashboard-metric/${id}`);
    }

    protected updateListReq(list: EntityListInterface<UserDashboardMetricInterface>): Observable<EntityListInterface<UserDashboardMetricInterface>> {
        throw new Error("Method not implemented.");
    }
}
