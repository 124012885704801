import { AbstractEntityRepositoryServices } from './abstract-entity-repository.services';
import {AbstractEntityWithClubRelationRepository} from "@ypa/state-management/shared/abstract";
import {Observable, switchMap, tap} from "rxjs";
import {BaseEntityInterface, BaseEntityParams, EntityWithClubRelationParams} from "@ypa/types/base-entity";
import {GetByTypeEnum} from "@ypa/enums/get-by-type";

export abstract class AbstractEntityWithClubRelationRepositoryService<T extends BaseEntityInterface, TBase, TClubRelation extends BaseEntityInterface, TClubBaseRelation, TParams extends EntityWithClubRelationParams, TClubRelationParams extends BaseEntityParams> extends AbstractEntityRepositoryServices<T, TBase, TParams> {
    protected constructor(
        private withClubRelationRepository: AbstractEntityWithClubRelationRepository<T, TClubRelation, TClubBaseRelation, TParams, TClubRelationParams>
    ) {
        super(withClubRelationRepository);
    }

    protected abstract override getByReq(params: EntityWithClubRelationParams): Observable<T[]>

    override getBy(params: TParams, mode = GetByTypeEnum.regular): Observable<T[]> {
        const key = `getBy${JSON.stringify(params)}`;
        if ((this.isRepositoryDataValid(key, mode === GetByTypeEnum.repositoryIfRequested) || this.withClubRelationRepository.isSynchronised || mode === GetByTypeEnum.repository) && mode !== GetByTypeEnum.force) {
            return this.withClubRelationRepository.getBy(params, mode);
        }

        return this.getByReq(params).pipe(
            tap(data => this.withClubRelationRepository.upsert(data)),
            tap(() => this.addTimeCheck(key)),
            switchMap(() => this.withClubRelationRepository.getBy(params, mode))
        );
    }
}
