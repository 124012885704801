export class MatchPasswordHelper {

    static regex(): RegExp {
        return /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z])(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]).*$/;
    }

    static uppercasePattern(): RegExp {
        return /(?=.*?[A-Z])/;
    }

    static lengthPattern(): RegExp {
        return /.{10,28}$/;
    }

    static lettersAndNumberPattern(): RegExp {
        return /(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/;
    }
}
