import {Injectable} from '@angular/core';
import {BaseLanguageInterface, LanguageInterface, LanguageParams} from "@ypa/types/language";
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {Observable, throwError} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {LanguageRepository} from "@ypa/state-management/shared/language";
import {environment} from "@ypa/constants/environments";
import { EntityListInterface } from '@ypa/types/base-entity';

@Injectable({providedIn: "root"})
export class LanguageService extends AbstractEntityRepositoryServices<LanguageInterface, BaseLanguageInterface, LanguageParams> {

    constructor(
        private http: HttpClient,
        private languageRepository: LanguageRepository
    ) {
        super(languageRepository)
    }

    protected createReq(form: Partial<LanguageInterface> | BaseLanguageInterface): Observable<LanguageInterface> {
        return throwError(() => new Error('Create language api is not implemented'));
    }

    protected getAllReq(): Observable<LanguageInterface[]> {
        return this.http.get<LanguageInterface[]>(`${environment.apiUrl}/language`);
    }

    protected getByIdReq(id: number): Observable<LanguageInterface> {
        return throwError(() => new Error('Get by ID api is not implemented'));
    }

    protected getByReq(params: LanguageParams): Observable<LanguageInterface[]> {
        return this.http.get<LanguageInterface[]>(`${environment.apiUrl}/language`, {params: params as {}});
    }

    protected removeReq(id: number): Observable<any> {
        return throwError(() => new Error('Remove request api is not implemented'));
    }

    protected updateReq(id: number, form: Partial<LanguageInterface>): Observable<LanguageInterface> {
        return throwError(() => new Error('Update request api is not implemented'));
    }

    protected updateListReq(list: EntityListInterface<LanguageInterface>): Observable<EntityListInterface<LanguageInterface>> {
        throw new Error('Method not implemented.');
    }
}
