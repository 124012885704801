import {Timezone} from "@ypa/types/shared/timezone";
import {timezones} from "@ypa/data/timezones";

export class TimezoneHelper {

    static getAll(): Timezone[] {
        return timezones;
    }

    static getByOffset(offset: number): Timezone {
        const timezone =  timezones.find(x => x.offset === offset);
        return timezone ? timezone : timezones[0];
    }
}
