// google-analytics.service.ts
import { Injectable } from '@angular/core';
import { IAnalyticsService } from '../analytics.interface';

declare let gtag: Function; // Assume you've already included Google Analytics gtag.js in your index.html

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService implements IAnalyticsService {

  constructor() { }

  trackPageView(app: string, url: string): void {
    gtag('config', 'GA_TRACKING_ID', {'page_path': url});
  }

  trackEvent(app: string, userId: number, eventCategory: string, eventAction: string, eventLabel?: string, eventValue?: number): void {
    gtag('event', eventAction, {
      'event_category': eventCategory,
      'event_label': eventLabel,
      'value': eventValue
    });
  }
}
