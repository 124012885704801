import {Injectable} from '@angular/core';
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {HttpClient} from "@angular/common/http";
import {
    BaseCoachDashboardMetricInterface,
    CoachDashboardMetricInterface,
    CoachDashboardMetricParams
} from "@ypa/types/coach-dashboard-metric";
import {CoachDashboardMetricRepository} from "@ypa/state-management/shared/coach-dashboard-metric";
import {Observable} from "rxjs";
import {environment} from "@ypa/constants/environments";
import {EntityListInterface} from '@ypa/types/base-entity';

@Injectable({providedIn: "root"})
export class CoachDashboardMetricService extends AbstractEntityRepositoryServices<CoachDashboardMetricInterface, BaseCoachDashboardMetricInterface, CoachDashboardMetricParams> {

    constructor(
        private http: HttpClient,
        private coachDashboardMetricRepository: CoachDashboardMetricRepository
    ) {
        super(coachDashboardMetricRepository)
    }

    protected createReq(form: Partial<CoachDashboardMetricInterface> | BaseCoachDashboardMetricInterface): Observable<CoachDashboardMetricInterface> {
        return this.http.post<CoachDashboardMetricInterface>(`${environment.apiUrl}/coach-dashboard-metric`, form);
    }

    protected getAllReq(): Observable<CoachDashboardMetricInterface[]> {
        return this.http.get<CoachDashboardMetricInterface[]>(`${environment.apiUrl}/coach-dashboard-metric`);
    }

    protected getByIdReq(id: number): Observable<CoachDashboardMetricInterface> {
        return this.http.get<CoachDashboardMetricInterface>(`${environment.apiUrl}/coach-dashboard-metric/${id}`);
    }

    protected getByReq(params: CoachDashboardMetricParams): Observable<CoachDashboardMetricInterface[]> {
        return this.http.get<CoachDashboardMetricInterface[]>(`${environment.apiUrl}/coach-dashboard-metric`, {params: params as NonNullable<unknown>});
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/coach-dashboard-metric/${id}`);
    }

    protected updateReq(id: number, form: Partial<CoachDashboardMetricInterface>): Observable<CoachDashboardMetricInterface> {
        return this.http.patch<CoachDashboardMetricInterface>(`${environment.apiUrl}/coach-dashboard-metric/${id}`, form);
    }

    protected updateListReq(list: EntityListInterface<CoachDashboardMetricInterface>): Observable<EntityListInterface<CoachDashboardMetricInterface>> {
        throw new Error('Method not implemented.');
    }
}
