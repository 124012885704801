import { Injectable } from '@angular/core';
import { createStore, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { AuthTokenInterface } from '@ypa/types/auth-token';
import { map } from 'rxjs';

const store = createStore(
    {name: 'AUTH_TOKEN'},
    withProps<AuthTokenInterface | any>(null)
);

persistState(store, {
    key: 'AUTH_TOKEN',
    storage: localStorageStrategy,
});

@Injectable({providedIn: "root"})
export class AuthTokenRepository {

    setAccessToken(accessToken: string): void {
        store.update((state) => {
            return {...state, accessToken};
        });
    }

    getAccessTokenSnapshot(): string {
        const value = store.getValue();
        return value.accessToken;
    }

    setRefreshToken(refreshToken: string): void {
        store.update((state: AuthTokenInterface | never) => {
            return {...state as AuthTokenInterface, refreshToken};
        });
    }

    getRefreshTokenSnapshot() {
        const value = store.getValue() as AuthTokenInterface;
        return value?.refreshToken;
    }

    getRefreshToken() {
        return store.pipe(
            map((tokens: AuthTokenInterface) => tokens.refreshToken)
        );
    }

    clear(): void {
        store.reset();
    }
}
