import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, tap} from 'rxjs';
import {environment} from '@ypa/constants/environments';
import {BaseCalculationInterface, CalculationInterface, CalculationParams} from '@ypa/types/calculation';
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {CalculationRepository} from "@ypa/state-management/shared/calculation";
import { EntityListInterface } from '@ypa/types/base-entity';

@Injectable({providedIn: "root"})
export class CalculationService extends AbstractEntityRepositoryServices<CalculationInterface, BaseCalculationInterface, CalculationParams> {

    constructor(
        private http: HttpClient,
        private calculationRepository: CalculationRepository
    ) {
        super(calculationRepository);
    }

    saveMetricCalculations(metricId: number, calculations: CalculationInterface[]): Observable<CalculationInterface[]> {
        return this.http.post<CalculationInterface[]>(`${environment.apiUrl}/calculation/metric/${metricId}/sync`, {calculations}).pipe(
            tap(response => {
                this.calculationRepository.removeByMetricId(metricId);
                this.calculationRepository.upsert(response);
            })
        )
    }

    getByMetricAndParentIdsFromRepository(metricId: number, parentId: number): Observable<CalculationInterface[]> {
        return this.calculationRepository.getByMetricAndParentIds(metricId, parentId);
    }

    protected getAllReq(): Observable<CalculationInterface[]> {
        return this.http.get<CalculationInterface[]>(`${environment.apiUrl}/calculation`);
    }

    protected createReq(form: BaseCalculationInterface | Partial<CalculationInterface>): Observable<CalculationInterface> {
        return this.http.post<CalculationInterface>(`${environment.apiUrl}/calculation`, form);
    }

    protected updateReq(id: number, form: Partial<CalculationInterface>): Observable<CalculationInterface> {
        return this.http.patch<CalculationInterface>(`${environment.apiUrl}/calculation/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/calculation/${id}`);
    }

    protected getByIdReq(id: number): Observable<CalculationInterface> {
        return this.http.get<CalculationInterface>(`${environment.apiUrl}/calculation/${id}`);
    }

    protected getByReq(params: CalculationParams): Observable<CalculationInterface[]> {
        return this.http.get<CalculationInterface[]>(`${environment.apiUrl}/calculation`, {params: params as {}});
    }

    protected updateListReq(list: EntityListInterface<CalculationInterface>): Observable<EntityListInterface<CalculationInterface>> {
        throw new Error('Method not implemented.');
    }
}
