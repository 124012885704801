import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "@ypa/constants/environments";
import {ForgotPassword, UpdatePasswordEmail, UpdatePasswordPhone} from "./interfaces/forgot-password";

@Injectable()
export class ForgotService {
    constructor(
        private http: HttpClient,
    ) {
    }

    email(data: ForgotPassword): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/auth/forgot-password`, data);
    }

    password(data: UpdatePasswordEmail): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/auth/change-password-by-token`, data);
    }

    passwordSms(data: UpdatePasswordPhone): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/auth/change-password-by-code`, data);
    }
}
