// mixpanel-analytics.service.ts
import { Injectable } from '@angular/core';
import { IAnalyticsService } from '../analytics.interface';
import mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root'
})
export class MixpanelAnalyticsService implements IAnalyticsService {

  // private mixpanelToken: string;  // TODO

  constructor(){
    // this.mixpanelToken = environment.mixpanel_token;  TODO
    this.init('test');
  }

  /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberof MixpanelService
   */
  init(userToken: string): void {
    mixpanel.init('921094f698c7bab147b3880418b34bed', {debug: false, track_pageview: true, persistence: 'localStorage'});  // TODO make variable for token
  }

  // Called after login
  setUserId(userId: string){
    //localStorage.setItem('userToken', userId);
    mixpanel.identify(userId);
  }

  // Called after logout
  clearUserId(){
    //localStorage.removeItem('userToken');
    mixpanel.reset();
  }

  /*
  identifyUser(): void {
    const userToken = localStorage.getItem('userToken');
    if (userToken) {
      mixpanel.identify(userToken);
    }
  }
*/

  trackPageView(app: string, url: string): void {
    // Mixpanel page view tracking implementation    
    const clubId = this.getClubId();
    mixpanel.track('Page View', { app, url, clubId });
  }

  trackPageViewWithUserId(app: string, userId: number, url: string): void {
    // Mixpanel page view tracking implementation    
    mixpanel.track('Page View', { app, url, userId });
  }

  trackEvent(app: string, userId: number, eventCategory: string, eventAction: string, eventLabel?: string, eventValue?: number): void {
    const clubId = this.getClubId();
    mixpanel.track(eventAction, { app, clubId, eventCategory, eventAction, eventLabel, eventValue, userId });    
  }

  getClubId(){
    try {
      const item : string | null= localStorage.getItem('CURRENT_CLUB');
      if (item) {
        const clubObject = JSON.parse(item);
        if (clubObject.data){
          return clubObject.data;
        }

      }
    } catch (e){
      // Do nothing
      console.log('Missing entry in localstorage');
    }
    return 0;
  }

}
