import {Observable} from "rxjs";
import {resetActiveId, selectActiveEntity, setActiveId} from "@ngneat/elf-entities";
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {BaseEntityInterface, BaseEntityParams} from "@ypa/types/base-entity";

export abstract class AbstractEntityWithActiveIdRepository<T extends BaseEntityInterface, TParams extends BaseEntityParams> extends AbstractEntityRepository<T, TParams> {
    get active$(): Observable<T | undefined> {
        return this.store.pipe(selectActiveEntity());
    }

    setActive(id: number) {
        this.store.update(setActiveId(id));
    }

    resetActive() {
        this.store.update(resetActiveId());
    }
}
