import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '@ypa/constants/environments';
import {
    BaseMetricClubInterface,
    MetricClubEnableInterface,
    MetricClubInterface,
    MetricClubParams
} from '@ypa/types/metric-club';
import {
    AbstractEntityClubRelationWithEnableAllRepositoryService,
} from "@ypa/data-access/abstract-repository-services";
import {MetricClubRepository} from "@ypa/state-management/shared/metric-club";
import { EntityListInterface } from '@ypa/types/base-entity';

@Injectable({providedIn: 'root'})
export class MetricClubService extends AbstractEntityClubRelationWithEnableAllRepositoryService<MetricClubInterface, BaseMetricClubInterface, MetricClubParams, MetricClubEnableInterface> {
    constructor(
        private clubRepository: MetricClubRepository,
        private http: HttpClient
    ) {
        super(clubRepository);
    }

    protected enableForAllReq(form: MetricClubEnableInterface): Observable<MetricClubInterface[]> {
        return this.http.post<MetricClubInterface[]>(`${environment.apiUrl}/metric-club/enabled`, form);;
    }

    protected getByClubIdReq(clubId: number): Observable<MetricClubInterface[]> {
        const params = {
            clubId
        };
        return this.http.get<MetricClubInterface[]>(`${environment.apiUrl}/metric-club`, {params});
    }

    protected getAllReq(): Observable<MetricClubInterface[]> {
        return this.http.get<MetricClubInterface[]>(`${environment.apiUrl}/metric-club`)
    }

    protected createReq(form: Partial<BaseMetricClubInterface>): Observable<MetricClubInterface> {
        return this.http.post<MetricClubInterface>(`${environment.apiUrl}/metric-club`, form);
    }

    protected updateReq(id: number, form: Partial<MetricClubInterface>): Observable<MetricClubInterface> {
        return this.http.patch<MetricClubInterface>(`${environment.apiUrl}/metric-club/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/metric-club/${id}`);
    }

    protected getByIdReq(id: number): Observable<MetricClubInterface> {
        return throwError(() => new Error('There is no get by id server api or it is not implemented'));
    }

    protected getByReq(params: MetricClubParams): Observable<MetricClubInterface[]> {
        return this.http.get<MetricClubInterface[]>(`${environment.apiUrl}/metric-club`, {params: params as {}});
    }

    protected updateListReq(list: EntityListInterface<MetricClubInterface>): Observable<EntityListInterface<MetricClubInterface>> {
        throw new Error('Method not implemented.');
    }
}
