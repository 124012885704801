import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
    withEntities,
} from '@ngneat/elf-entities';
import {SportInterface, SportParams} from '@ypa/types/sport';
import {
    AbstractEntityWithClubRelationRepository
} from "@ypa/state-management/shared/abstract";
import {BaseSportClubInterface, SportClubInterface, SportClubParams} from "@ypa/types/sport-club";
import {SportClubService} from "@ypa/data-access/sport-club";

const name = 'SPORTS';

@Injectable({providedIn: "root"})
export class SportRepository extends AbstractEntityWithClubRelationRepository<SportInterface, SportClubInterface, BaseSportClubInterface, SportParams, SportClubParams>{

    protected readonly store = createStore({ name }, withEntities<SportInterface>());

    constructor(
        private sportClubService: SportClubService
    ) {
        super(sportClubService);
    }

    protected isMatchedEntityWithClubRelation(clubRelations: SportClubInterface, obj: SportInterface): boolean {
        return clubRelations.sportId === obj.id;
    }
}
