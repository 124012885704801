import {Observable} from "rxjs";
import {
    deleteEntities,
    getAllEntities,
    selectAllEntities,
    selectEntity, selectManyByPredicate,
    upsertEntities
} from "@ngneat/elf-entities";
import {BaseEntityInterface, BaseEntityParams} from "@ypa/types/base-entity";

export abstract class AbstractEntityRepository<T extends BaseEntityInterface, TParams extends BaseEntityParams> {
    protected abstract store: any;

    isSynchronised = false;

    get entities$(): Observable<T[]> {
        return this.store.pipe(selectAllEntities());
    }

    upsert(entity: T | T[]): void {
        // don't set to store entities without id
        if (Array.isArray(entity)) {
            entity = entity.filter(x => (x.id !== null && typeof x.id !== "undefined"));
            this.store.update(upsertEntities(entity));
        } else if (entity.id !== null && typeof entity.id !== "undefined") {
            this.store.update(upsertEntities(entity));
        }
    }

    getById(id: number): Observable<T> {
        return this.store.pipe(selectEntity(id));
    }

    getBy(params: TParams): Observable<T[]> {
        return this.store.pipe(selectManyByPredicate((obj: T) => this.isMatchParams(params, obj)));
    }

    remove(id: number | number[]) {
        return this.store.update(deleteEntities(id));
    }

    clear() {
        this.isSynchronised = false;
        this.store.reset();
    }

    getSnapshot(): T[] {
        return this.store.query(getAllEntities());
    }

    protected isMatchParams(params: {}, obj: T): boolean {
        const paramsArray: { key: string, param: string | string[] }[] = [];
        Object.keys(params).forEach(key => {
            // @ts-ignore
            const param = params[key];
            if (typeof param !== "undefined") {
                paramsArray.push({
                    key,
                    param
                });
            }
        })

        let matchCount = 0;

        paramsArray.forEach(param => {
            const isArray = Array.isArray(param.param);
            // @ts-ignore
            const value = obj[param.key];
            if (isArray && (param.param as string[]).some(x => x === value)) {
                matchCount++;
            } else if (param.param === value) {
                matchCount++;
            }
        })

        return paramsArray.length === matchCount;
    }
}
