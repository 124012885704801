import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import {MatchPasswordHelper} from "@ypa/helpers/match-password";

export class CustomPasswordValidatorHelper {
    //Match password in fields
    static matchValidator(passwordPropName: string, confirmPasswordPropName: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let errorObject;

            if (control instanceof FormGroup && control.controls[passwordPropName].value) {
                const passwordValue = control.controls[passwordPropName].value;
                const confPasswordValue = control.controls[confirmPasswordPropName].value;

                const isUppercaseValid = MatchPasswordHelper.uppercasePattern().test(passwordValue);
                const isUseSymbols = MatchPasswordHelper.regex().test(passwordValue);
                const isLettersAndNumberValid = MatchPasswordHelper.lettersAndNumberPattern().test(passwordValue);
                const isLengthValid = MatchPasswordHelper.lengthPattern().test(passwordValue);
                const isValidConfirm = passwordValue === confPasswordValue;

                errorObject = {
                    errorUppercase: isUppercaseValid,
                    errorSymbols: isUseSymbols,
                    errorLettersNumber: isLettersAndNumberValid,
                    errorLength: isLengthValid,
                    errorConfirm: isValidConfirm,
                    isPasswordMatch: false
                };

                if (
                    !isUppercaseValid ||
                    !isUseSymbols ||
                    !isLettersAndNumberValid ||
                    !isLengthValid ||
                    !isValidConfirm
                ) {
                    return errorObject;
                } else {
                    return {
                        errorUppercase: true,
                        errorSymbols: true,
                        errorLettersNumber: true,
                        errorLength: true,
                        errorConfirm: true,
                        isPasswordMatch:true
                    };
                }
            } else {
                return null;
            }
        };
    }
}
