import {Injectable} from '@angular/core';
import {BaseUserInterface, RegisterUserInterface, UserInterface} from '@ypa/types/user';
import {HttpClient} from '@angular/common/http';
import {Observable, of, tap, throwError} from 'rxjs';
import {environment} from '@ypa/constants/environments';
import {AuthTokenInterface} from '@ypa/types/auth-token';
import {AbstractPropertyRepositoryService} from "@ypa/data-access/abstract-repository-services";
import {UserAuthRepository} from "@ypa/state-management/shared/auth";

@Injectable({providedIn: "root"})
export class UserAuthService extends AbstractPropertyRepositoryService<UserInterface, BaseUserInterface> {
    constructor(
        private http: HttpClient,
        private userAuthRepository: UserAuthRepository
    ) {
        super(userAuthRepository)
    }

    signUpUser(user: Partial<RegisterUserInterface>): Observable<AuthTokenInterface> {
        return this.http.post<AuthTokenInterface>(`${environment.apiUrl}/auth/register`, user);
    }

    login(user: Partial<UserInterface>, isAdmin = false): Observable<AuthTokenInterface | null> {

        const path: string = isAdmin ? `admin/auth/login` : `auth/login`;

        return this.http.post<AuthTokenInterface>(`${environment.apiUrl}/${path}`, user);
    }

    sendPushToken(token: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}/user/set-app-device-token`, {appDeviceToken: token});
    }

    logout(): Observable<any> {
        return this.http.post(`${environment.apiUrl}/auth/logout`, {});
    }

    refresh(): Observable<AuthTokenInterface> {
        return this.http.post<AuthTokenInterface>(`${environment.apiUrl}/auth/refresh`, {});
    }

    validatePartial(data: Partial<UserInterface>): Observable<AuthTokenInterface> {
        return this.http.post<AuthTokenInterface>(`${environment.apiUrl}/auth/validate-partial`, data)
    }

    uploadAvatar(file: Blob): Observable<UserInterface> {
        
        if (file.size > 1024 * 1024 * 2) {
            return throwError(() => new Error('File is too big. Max size is 2MB'));
        }        
        
        const formParams = new FormData();
        formParams.append('file', file);
        return this.http.post<UserInterface>(`${environment.apiUrl}/user/avatar`, formParams, {
            // headers: new HttpHeaders({
            //     'Content-Type': 'multipart/form-data',
            // })
        }).pipe(
            tap(user => this.userAuthRepository.update(user))
        );
    }

    protected getReq(): Observable<UserInterface> {
        return this.http.get<UserInterface>(`${environment.apiUrl}/auth/profile`);
    }

    protected createReq(form: BaseUserInterface | Partial<UserInterface>): Observable<UserInterface> {
        return throwError(() => new Error('Create profile api is not implemented'))
    }

    protected updateReq(id: number, form: Partial<UserInterface>): Observable<UserInterface> {
        return this.http.patch<UserInterface>(`${environment.apiUrl}/auth/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<UserInterface>(`${environment.apiUrl}/admin/auth/${id}`);
    }
}
