import {Injectable} from "@angular/core";
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {MetricHintInterface, MetricHintParams} from "@ypa/types/metric-hint";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";
import {MetricReportGroupInterface} from "@ypa/types/metric-report-group";

const name = 'METRIC_HINTS'

@Injectable({providedIn: "root"})
export class MetricHintRepository extends AbstractEntityRepository<MetricHintInterface, MetricHintParams> {
    protected readonly store = createStore(
        {name},
        withEntities<MetricReportGroupInterface>()
    );
}
