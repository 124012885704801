import {ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {inject} from "@angular/core";
import {UserAuthService} from "@ypa/data-access/user";
import {catchError, map, of, tap} from "rxjs";
import {NavController} from "@ionic/angular";

export const authGuardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    // private router: Router,
    const userAuthService = inject(UserAuthService);
    const navCtrl = inject(NavController);
    // return true;
    return userAuthService.get(true).pipe(
        catchError(error => of(null)),
        map((user) => {
            if (user) {
                return true;
            }
            // if (user && user.isOnboardingCompleted) {
            //     return true;
            // } else if (user && !user.isOnboardingCompleted) {
            //     return navCtrl.router.createUrlTree(['/onboarding']);
            // }
            return navCtrl.navigateRoot(['/auth']).then();
        })
    );
};
