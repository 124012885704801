import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { withEntities, } from '@ngneat/elf-entities';
import { RoleInterface } from '@ypa/types/role';
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {BaseEntityParams} from "@ypa/types/base-entity";

const name = 'ROLES';

@Injectable({providedIn: "root"})
export class RoleRepository extends AbstractEntityRepository<RoleInterface, BaseEntityParams>{
    protected readonly store = createStore({name}, withEntities<RoleInterface>());
}
