import { TaskConfigWeekDayEnum } from "@ypa/enums/task-config-week-day";
import { TaskConfigPropEnum } from "@ypa/enums/task-config-prop";

export class BaseTaskConfigInterface implements Record<string, any> {
}

export abstract class BaseTaskConfig implements BaseTaskConfigInterface {
    id: number;
    abstract parent: BaseTaskConfig;
    parentId: number;
    abstract children: BaseTaskConfig[];
    key: TaskConfigPropEnum;
    value: string;
}

export interface TaskWeeklyConfigInterface extends BaseTaskConfigInterface {
    weekDay: TaskConfigWeekDayEnum;
    timeList: string[];
}

export interface TaskConfigInterface<T extends BaseTaskConfigInterface> extends BaseTaskConfigInterface {
    configs: T[];
}
