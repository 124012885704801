import {Injectable} from '@angular/core';
import {createStore} from '@ngneat/elf';
import {
    withEntities,
} from '@ngneat/elf-entities';
import {ClubInterface, ClubParams} from '@ypa/types/club';
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";

const name = 'CLUBS';

@Injectable({providedIn: "root"})
export class ClubRepository extends AbstractEntityRepository<ClubInterface, ClubParams> {
    protected readonly store = createStore({name}, withEntities<ClubInterface>());
}
