import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {LanguageService} from "./language.service";
import {BehaviorSubject, filter, map, Observable, of, tap} from "rxjs";
import {LanguageInterface} from "@ypa/types/language";
import {environment} from "@ypa/constants/environments";
import {BaseTranslationFieldInterface} from "@ypa/types/translation-field";

@Injectable({
    providedIn: "root"
})
export class LanguageCurrentService {

    private currentLanguage = new BehaviorSubject<LanguageInterface | null>(null);
    currentLanguage$ = this.currentLanguage.asObservable().pipe(
        filter((l) => l !== null)
    );

    private currentFormControlLanguage = new BehaviorSubject<LanguageInterface | null>(null);
    currentFormControlLanguage$ = this.currentFormControlLanguage.asObservable().pipe(
        filter((l) => l !== null)
    );


    private uiLanguages = new BehaviorSubject<LanguageInterface[]>([]);
    uiLanguages$ = this.uiLanguages.asObservable().pipe(
        filter((l) => l.length > 0)
    );

    private allowedLanguages = environment.allowedLanguages;

    constructor(
        private translateService: TranslateService,
        private languageService: LanguageService
    ) {
    }

    setUiLanguages(uiLanguages: LanguageInterface[]) {
        this.uiLanguages.next(uiLanguages);
    }

    setCurrentLanguage(lang: LanguageInterface) {
        this.currentLanguage.next(lang);
    }

    setCurrentFormControlLanguage(lang: LanguageInterface) {
        this.currentFormControlLanguage.next(lang);
    }

    setCurrentLanguageByCode(languageId?: number | null) {
        const language = languageId ? languageId : this.getDefaultLanguageId();
        const currentLanguage = this.getLanguagesListSnapshot().find((l) => l.id === language);
        const code = currentLanguage ? currentLanguage.code : environment.defaultLanguage;
        this.translateService.use(code);
        localStorage.setItem('locale', code);
        this.currentLanguage.next(currentLanguage as LanguageInterface);
    }

    getLanguageList(): Observable<LanguageInterface[]> {
        return this.languageService.sync().pipe(
            map((languageList) =>
                languageList.filter((l) => {
                    return this.allowedLanguages.some((v) => {
                        return v === l.code;
                    });
                })
            ),
            tap((uiLanguages) => {
                const userLanguage = localStorage.getItem('locale') ? localStorage.getItem('locale') : environment.defaultLanguage;
                const currentLanguage = uiLanguages.filter((l) => l.code === userLanguage);
                this.setCurrentLanguage(currentLanguage[0]);
                this.setCurrentFormControlLanguage(currentLanguage[0]);
                this.setUiLanguages(uiLanguages)
            })
        );
    }

    getLanguagesListSnapshot(): LanguageInterface[] {
        return this.uiLanguages.value;
    }

    getCurrentLanguageSnapshot(): LanguageInterface | null {
        return this.currentLanguage.value;
    }

    getCurrentFormControlLanguageSnapshot(): LanguageInterface | null {
        return this.currentFormControlLanguage.value;
    }

    getTranslateObs(translations: BaseTranslationFieldInterface): Observable<string> {
        if (!translations) return of('');
        return this.currentLanguage$.pipe(
            map((currentLanguage) => {
                return this.findTranslate(translations, currentLanguage)
            }));
    }

    getTranslate(translations: BaseTranslationFieldInterface): string {
        if (!translations) return '';
        const currentLanguage = this.getCurrentLanguageSnapshot();
        return this.findTranslate(translations, currentLanguage)
    }


    private findTranslate(translations: BaseTranslationFieldInterface, currentLanguage: LanguageInterface | null): string {
        if (!translations || !translations.translations || !translations.translations.length) {
            return '';
        }
        const translation = translations.translations.find(t => {
            return t.languageId === currentLanguage?.id;
        });
        return translation ? translation.text as string : '';
    }

    private getDefaultLanguageId(): number {
        const defaultLanguage = this.getLanguagesListSnapshot().find((l) => l.code === environment.defaultLanguage);
        // @ts-ignore
        return defaultLanguage.id;
    }

}
