import {Injectable} from "@angular/core";
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {Observable, throwError} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "@ypa/constants/environments";
import {BaseMetricHintTextInterface, MetricHintTextInterface, MetricHintTextParams} from "@ypa/types/metric-hint-text";
import {MetricHintTextRepository} from "@ypa/state-management/shared/metric-hint-text";
import {EntityListInterface} from "@ypa/types/base-entity";

@Injectable({providedIn: "root"})
export class MetricHintTextService extends AbstractEntityRepositoryServices<MetricHintTextInterface, BaseMetricHintTextInterface, MetricHintTextParams> {
    constructor(
        private http: HttpClient,
        private metricHintTextRepository: MetricHintTextRepository
    ) {
        super(metricHintTextRepository);
    }

    protected getAllReq(): Observable<MetricHintTextInterface[]> {
        return throwError(() => new Error('Get all metric hint texts is not implemented'))
    }

    protected createReq(form: BaseMetricHintTextInterface): Observable<MetricHintTextInterface> {
        return this.http.post<MetricHintTextInterface>(`${environment.apiUrl}/metric-hint-text`, form);
    }

    protected updateReq(id: number, form: Partial<MetricHintTextInterface>): Observable<MetricHintTextInterface> {
        return this.http.patch<MetricHintTextInterface>(`${environment.apiUrl}/metric-hint-text/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/metric-hint-text/${id}`);
    }

    protected getByIdReq(id: number): Observable<MetricHintTextInterface> {
        return throwError(() => new Error('Get metric hint text by id method is not implemented'))
    }

    protected getByReq(params: MetricHintTextParams): Observable<MetricHintTextInterface[]> {
        return this.http.get<MetricHintTextInterface[]>(`${environment.apiUrl}/metric-hint-text`, {params: params as {}})
    }

    protected updateListReq(list: EntityListInterface<MetricHintTextInterface>): Observable<EntityListInterface<MetricHintTextInterface>> {
        throw new Error("Method not implemented.");
    }
}
