import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@ypa/constants/environments';
import {BaseSportInterface, SportInterface, SportParams} from '@ypa/types/sport';
import {Injectable} from '@angular/core';
import {AbstractEntityWithClubRelationRepositoryService} from "@ypa/data-access/abstract-repository-services";
import {SportRepository} from "@ypa/state-management/shared/sport";
import {BaseSportClubInterface, SportClubInterface, SportClubParams} from "@ypa/types/sport-club";
import {EntityListInterface} from '@ypa/types/base-entity';

@Injectable({providedIn: "root"})
export class SportService extends AbstractEntityWithClubRelationRepositoryService<SportInterface, BaseSportInterface, SportClubInterface, BaseSportClubInterface, SportParams, SportClubParams> {
    constructor(
        private http: HttpClient,
        private sportRepository: SportRepository
    ) {
        super(sportRepository)
    }

    protected getByReq(params: SportParams): Observable<SportInterface[]> {
        return this.http.get<SportInterface[]>(`${environment.apiUrl}${environment.endpointMainPrefix}/sport`, {params: params as {}});
    }

    protected getAllReq(): Observable<SportInterface[]> {
        return this.http.get<SportInterface[]>(`${environment.apiUrl}${environment.endpointMainPrefix}/sport`)
    }

    protected createReq(form: BaseSportInterface | Partial<SportInterface>): Observable<SportInterface> {
        return this.http.post<SportInterface>(`${environment.apiUrl}/sport`, form);
    }

    protected updateReq(id: number, form: Partial<SportInterface>): Observable<SportInterface> {
        return this.http.patch<SportInterface>(`${environment.apiUrl}/sport/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<SportInterface>(`${environment.apiUrl}/sport/${id}`);
    }

    protected getByIdReq(id: number): Observable<SportInterface> {
        return this.http.get<SportInterface>(`${environment.apiUrl}/sport/${id}`)
    }

    protected updateListReq(list: EntityListInterface<SportInterface>): Observable<EntityListInterface<SportInterface>> {
        throw new Error('Method not implemented.');
    }

    // some Sviat's legacy
    sortNested(clubs: SportInterface[]) {
        return clubs.reduce((acc: SportInterface[], curr: SportInterface) => {
            const parentIdx = acc.findIndex(
                (club) => club.id === curr.parentId
            );
            if (parentIdx !== -1) {
                acc.splice(parentIdx + 1, 0, curr);
            } else {
                acc.push(curr);
            }
            return acc;
        }, []);
    }

    calculateDeep(
        club: SportInterface,
        clubs: SportInterface[],
        deep = 0
    ): number {
        const parent = clubs.find((c) => c.id === club.parentId);

        if (parent) {
            return this.calculateDeep(parent, clubs, deep + 1);
        }

        return deep;
    }
}
