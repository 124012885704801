import {ErrorsObjectInterface} from "../interfaces/errors-object.interface";
import {ValidationErrorItem} from "../interfaces/validation-error-item.interface";

export class OvdalErrorsHelper {
    static setErrors<FormType extends {
        controls?: any,
        setErrors(val: { [K: string]: string }): void
    }>(form: FormType, errors: ErrorsObjectInterface) {
        if (!form || !errors) {
            return;
        }

        Object.keys(errors).forEach(propertyName => {
            const currentFormControl: any = form?.controls[propertyName];

            if (OvdalErrorsHelper.isArrayOfErrors(errors[propertyName])) {
                currentFormControl.markAsTouched();
                currentFormControl.setErrors(OvdalErrorsHelper.prepareFormErrors(errors[propertyName] as ValidationErrorItem[]));
            } else if (Array.isArray(errors[propertyName])) {
                (errors[propertyName] as ErrorsObjectInterface[]).forEach((error: ErrorsObjectInterface) => OvdalErrorsHelper.setErrors(currentFormControl, error));
            } else {
                OvdalErrorsHelper.setErrors(currentFormControl, errors[propertyName] as ErrorsObjectInterface);
            }
        })
    }

    private static isArrayOfErrors(propertyData: any): boolean {
        if (Array.isArray(propertyData) && propertyData.length && OvdalErrorsHelper.instanceOfValidationErrorItem(propertyData[0])) {
            return true;
        }
        return false;
    }

    private static prepareFormErrors(errors: ValidationErrorItem[]): { [K: string]: string } {
        const formErrors: { [K: string]: string } = {}
        errors.forEach(error => formErrors[error.key] = error.errorText);
        console.log(formErrors);
        return formErrors;
    }

    private static instanceOfValidationErrorItem(obj: any): boolean {
        return 'key' in obj && 'errorText' in obj;
    }
}
