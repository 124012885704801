import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@ypa/constants/environments';
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {BaseTaskInterface, TaskInterface, TaskParams} from "@ypa/types/task";
import {TasksRepository} from "@ypa/state-management/shared/tasks";
import {EntityListInterface} from '@ypa/types/base-entity';

@Injectable({providedIn: "root"})
export class TasksService extends AbstractEntityRepositoryServices<TaskInterface, BaseTaskInterface, TaskParams> {

    constructor(
        private http: HttpClient,
        private tasksRepository: TasksRepository
    ) {
        super(tasksRepository)
    }

    protected getAllReq(): Observable<TaskInterface[]> {
        return this.http.get<TaskInterface[]>(`${environment.apiUrl}${environment.endpointMainPrefix}/task`);
    }
    protected createReq(form: BaseTaskInterface | Partial<TaskInterface>): Observable<TaskInterface> {
        return this.http.post<TaskInterface>(`${environment.apiUrl}/task`, form);
    }
    protected updateReq(id: number, form: Partial<TaskInterface>): Observable<TaskInterface> {
        return this.http.put<TaskInterface>(`${environment.apiUrl}/task/${id}`, form);
    }
    protected removeReq(id: number): Observable<any> {
        return this.http.delete<TaskInterface>(`${environment.apiUrl}/task/${id}`);
    }

    protected getByIdReq(id: number): Observable<TaskInterface> {
        return this.http.get<TaskInterface>(`${environment.apiUrl}/task/${id}`);
    }

    protected getByReq(params: TaskParams): Observable<TaskInterface[]> {
        return this.http.get<TaskInterface[]>(`${environment.apiUrl}${environment.endpointMainPrefix}/task`, {params: params as {}});
    }

    protected override updateListReq(list: EntityListInterface<TaskInterface>): Observable<EntityListInterface<TaskInterface>> {
        throw new Error('Method not implemented.');
    }
}
