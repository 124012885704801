import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";
import {MatchPhoneHelper} from "@ypa/helpers/match-phone";

export class CustomPhoneValidatorHelper {
    static matchPhone(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }
            const isValid = MatchPhoneHelper.regex().test(control.value);
            return isValid ? null : ['Phone number is not valid. Correct number formats: +45XXXXXXX, +49XXXXXXXXXX, +38XXXXXXXXX'];
        }
    }
}
