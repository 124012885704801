import {Injectable} from "@angular/core";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";
import {CommonWidgetInterface, WidgetParams} from "@ypa/types/widget";
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";

const name = 'REPORT_TAB_WIDGET';

@Injectable({providedIn: "root"})
export class ReportTabWidgetRepository extends AbstractEntityRepository<CommonWidgetInterface, WidgetParams> {
    protected readonly store = createStore(
        {name},
        withEntities<CommonWidgetInterface>()
    );
}
