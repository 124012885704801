import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
    withActiveId,
    withEntities,
} from '@ngneat/elf-entities';
import {CommonCollectorInterface, CommonCollectorParams} from '@ypa/types/collector';
import {AbstractEntityWithActiveIdRepository} from "@ypa/state-management/shared/abstract";

const name = 'COLLECTORS';

@Injectable({providedIn: "root"})
export class CollectorRepository extends AbstractEntityWithActiveIdRepository<CommonCollectorInterface, CommonCollectorParams> {

    protected readonly store = createStore(
        {name},
        withEntities<CommonCollectorInterface>(),
        withActiveId(),
    );
}
