interface RequestTimeCheckData {
    key: string,
    time: Date
}

export abstract class AbstractRequestCheckerService {
    private readonly maxDiffTime = 10000; //ms
    // private readonly maxDiffTime = 1000; //ms
    private requests: RequestTimeCheckData[] = [];

    addTimeCheck(key: string) {
        const timeCheck = this.requests.find(x => x.key === key);
        if (!timeCheck) {
            this.requests.push({
                key,
                time: new Date()
            })
        } else {
            timeCheck.time = new Date();
        }
    }

    isRepositoryDataValid(key: string, anyTimeAgo = false): boolean {
        const timeCheckData = this.requests.find(x => x.key === key);
        if (!timeCheckData) {
            return false;
        } else if (anyTimeAgo) {
            return true;
        }

        return this.isDataValid(timeCheckData.time);
    }

    protected clearRequests() {
        this.requests = [];
    }

    private isDataValid(timeCheck: Date): boolean {
        const now = new Date();
        // @ts-ignore
        if (Math.abs((now - timeCheck) < this.maxDiffTime)) {
            return true
        }
        return false;
    }
}
