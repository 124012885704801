import {Injectable} from "@angular/core";
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {BaseTimetableInterface, TimetableInterface, TimetableParams} from "@ypa/types/timetable";
import {TimetableRepository} from "timetable";
import {Observable} from "rxjs";
import {EntityListInterface} from "@ypa/types/base-entity";
import {HttpClient} from "@angular/common/http";
import {environment} from "@ypa/constants/environments";

@Injectable({providedIn: "root"})
export class TimetableService extends AbstractEntityRepositoryServices<TimetableInterface, BaseTimetableInterface, TimetableParams> {

    constructor(
        private http: HttpClient,
        timetableRepository: TimetableRepository
    ) {
        super(timetableRepository);
    }

    protected createReq(form: Partial<TimetableInterface> | BaseTimetableInterface): Observable<TimetableInterface> {
        return this.http.post<TimetableInterface>(`${environment.apiUrl}/coach/timetable`, form);
    }

    protected getAllReq(): Observable<TimetableInterface[]> {
        return this.http.get<TimetableInterface[]>(`${environment.apiUrl}/coach/timetable`);
    }

    protected getByIdReq(id: number): Observable<TimetableInterface> {
        return this.http.get<TimetableInterface>(`${environment.apiUrl}/coach/timetable/${id}`);
    }

    protected getByReq(params: TimetableParams): Observable<TimetableInterface[]> {
        throw new Error("Timetable get by method is not implemented.");
    }

    protected removeReq(id: number): Observable<any> {
        throw new Error("Timetable remove method not implemented.");
    }

    protected updateListReq(list: EntityListInterface<TimetableInterface>): Observable<EntityListInterface<TimetableInterface>> {
        throw new Error("Timetable update list method not implemented.");
    }

    protected updateReq(id: number, form: Partial<TimetableInterface>): Observable<TimetableInterface> {
        return this.http.patch<TimetableInterface>(`${environment.apiUrl}/coach/timetable/${id}`, form);
    }
}
